/* eslint-disable @typescript-eslint/no-explicit-any */
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest } from '../config/msal-auth-config.';
import { authInstance } from '../../app/app';
import { RuntimeConfig } from '../config/runtime-config';
import { AuthenticationProvider } from '../authentication/types/authentication-provider';
import { AuthenticationInfo } from '../authentication/types/authentication-info';
import { AuthContextProps } from 'react-oidc-context';

export class AuthenticationService {
	public static async getAuthenticationInfo(): Promise<AuthenticationInfo> {
		try {
			const authInfo =
				authInstance.authProvider === AuthenticationProvider.Microsoft
					? await this.getMicrosoftToken()
					: await this.getGoogleToken();
			return authInfo;
		} catch (error) {
			console.log(error);
			return { authProvider: authInstance.authProvider, token: '' };
		}
	}

	private static async getGoogleToken(): Promise<AuthenticationInfo> {
		const googleAuthInstance = authInstance.googleAuthInstance();
		if (googleAuthInstance.isAuthenticated && googleAuthInstance.user && !googleAuthInstance.user.expired) {
			this.checkAndRefreshGoogleToken(googleAuthInstance);
			const access_token = googleAuthInstance.user!.access_token;
			return { authProvider: AuthenticationProvider.Google, token: access_token };
		} else {
			try {
				const user = await googleAuthInstance.signinPopup();
				return { authProvider: AuthenticationProvider.Google, token: user.access_token! };
			} catch (error) {
				console.log(error);
				return { authProvider: AuthenticationProvider.Google, token: '' };
			}
		}
	}

	private static checkAndRefreshGoogleToken(googleAuthInstance: AuthContextProps) {
		const tokenLifetimeInSeconds = 60*60;
		if (
			authInstance.googleAuthInstance().user &&
			authInstance.googleAuthInstance().user!.expires_in &&
			authInstance.googleAuthInstance().user!.expires_in! < (tokenLifetimeInSeconds/2)
		) {
			googleAuthInstance.signinSilent().then(() => {
				// Token has successfully been refreshed
			});
		}
	}

	private static async getMicrosoftToken(): Promise<AuthenticationInfo> {
		const microsoftAuthInstance = authInstance.microsoftAuthInstance().instance;
		const accounts = microsoftAuthInstance.getAllAccounts();
		const account = accounts[0];
		if (account) {
			try {
				const response = await microsoftAuthInstance.acquireTokenSilent({
					...loginRequest,
					account,
				});
				return { authProvider: AuthenticationProvider.Microsoft, token: response?.accessToken };
			} catch (error) {
				if (error instanceof InteractionRequiredAuthError) {
					return await microsoftAuthInstance
						.acquireTokenPopup({
							scopes: RuntimeConfig.getMicrosoftClientScopes(),
						})
						.catch((error_: any) => {
							microsoftAuthInstance.logoutRedirect({
								account,
								postLogoutRedirectUri: RuntimeConfig.getLogoutRedirectURI(),
							});
							return error_;
						});
				} else {
					await microsoftAuthInstance.logoutRedirect({
						account,
						postLogoutRedirectUri: RuntimeConfig.getLogoutRedirectURI(),
					});
					return { authProvider: AuthenticationProvider.Microsoft, token: '' };
				}
			}
		} else {
			return { authProvider: AuthenticationProvider.Microsoft, token: '' };
		}
	}
}
