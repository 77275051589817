/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import styles from './meeting-update-create.modal.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import { MeetingContract, TagContract } from '../../../store/meetings/types';
import {
	getDayPastSevenDays,
	getStartOfWeekToDate,
	getHour,
	getMin,
	getDateFormatL,
	getHourFormat,
	getRightTimeSlot,
	getDateFormatWithYear,
	getStartOfDayDate,
} from '../../../shared/utils/date.util';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	IconClose,
	IconDownArrow,
	IconMeetingCreateModal,
	IconMeetings,
	IconSimplePlus,
} from '../../../shared/utils/icon';
import { getCurrentUserContacts } from '../../../store/user/slice';
import { Avatar } from '../../../shared/utils/avatar';
import { AuthenticationProvider, ContactsGraphContract } from '../../../store/user/type';
import {
	addLastMeetings,
	getLastMeetings,
	getMeetingsForAWeek,
	getMeetingsForSelectOption,
	updateMeetingTags,
	updateSelectedMeetingTags,
} from '../../../store/meetings/slice';
import MeetingFormDatePickerModal from './meeting-form-date-picker.modal';
import MeetingFormHoursPickerModal from './meeting-form-hours-picker';
import { MeetingForSeriesContract } from '../../../store/series/type';
import { meetingsForSeries } from '../../../store/series/slice';
import TagsFormModal from './tags-form.modal';
import { hexToRgb } from '../../../shared/utils/hextorgb';
import SimpleEditor from '../../../shared/utils/simple-editor';
import { ProjectContract } from '../../../store/project/type';
import { PersonaSize } from '@fluentui/react';
import useClickOutside from '../../../shared/hooks/use-click-outside.hook';
import Input, { Items } from '../../../shared/components/input/input.component';
import FormLayout, { FormType } from '../../../shared/components/layouts/form-layout.component';
import { Attendees } from './meeting-create.modal';
import { AttendeeMerge, attendeeMergeMapper } from '../../../shared/utils/mappers/attendee-merge.mapper';
import AttendeesInput from '../../../shared/components/input/attendees-input.component';
import useMergeAttendees from '../../../shared/hooks/use-merge-attendees.hook';
import { name } from '@azure/msal-browser/dist/packageMetadata';
import Tags from '../../../shared/components/tags/tags.component';
import imgGoogle from '../../../../images/icones/google-meets.svg';

export type FormData = {
	eventSubject: string;
	eventLocation: string;
	eventStart: Date;
	eventEnd: Date;
	isOnline: boolean;
	bodyContent: string;
	attendees: Attendees[];
	serial: boolean;
	meeting: MeetingContract;
	project: ProjectContract;
	isBot: boolean;
};

interface Props {
	meeting?: MeetingContract;
	onSubmit: (data: FormData) => void;
	handleClose: () => void;
	date?: string;
	handleNewAttendees: (newAtt: Attendees[]) => void;
	type: FormType;
	handleNewTags: (tags: Array<{ tag: string; color: string }>) => void;
	projectId?: string;
}

const MeetingFormComponent: React.FC<Props> = ({
	meeting,
	onSubmit,
	handleClose,
	date,
	handleNewAttendees,
	type,
	handleNewTags,
	projectId,
}) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { user, contacts } = useAppSelector((state) => state.user);
	const { lastMeetings, meetingsOption, prevMeeting } = useAppSelector((state) => state.meetings);
	const { selectedMeetingInformation } = useAppSelector((state) => state.meetings);
	const { projects } = useAppSelector((state) => state.project);

	const dataSchema = yup.object().shape({
		eventSubject: yup.string().required(),
		eventLocation: yup.string(),
		eventStart: yup.date().required(),
		eventEnd: yup.date().required(),
		attendees: yup
			.array()
			.min(1, 'At least one attendee is required')
			.of(
				yup.object().shape({
					type: yup.string().required(),
					emailAddress: yup.object().shape({
						address: yup.string().email().required(),
					}),
				}),
			),
		serial: yup.boolean(),
		meeting: yup.object(),
		project: yup.object(),
		isBot: yup.boolean(),
	});

	const { register, handleSubmit, setValue, trigger } = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	// --- TAGS

	const [tagsModalOpen, setTagsModalOpen] = React.useState(false);

	const [tagsMeeting, setTagsMeeting] = React.useState<TagContract[]>();

	const [newTags, setNewTags] = React.useState<Array<{ tag: string; color: string }>>([]);

	React.useEffect(() => {
		if (meeting) setTagsMeeting(meeting.tags);
	}, [meeting]);

	const deleteTag = (tag: TagContract) => {
		const updatedTags = tagsMeeting?.filter((tagsD) => tagsD !== tag);
		setTagsMeeting(updatedTags);

		if (meeting) {
			const tags = meeting.tags.filter((tagsD) => tagsD !== tag);

			dispatch(
				updateMeetingTags({
					tags,
					selectedMeetingID: meeting.iCalUId,
				}),
			).then((response) => {
				dispatch(updateSelectedMeetingTags(response.payload as TagContract[]));
			});
		} else if (newTags) {
			const updatesTags = newTags.filter((tagToDelete) => tagToDelete.tag !== tag.title);
			setNewTags(updatesTags);
			handleNewTags(updatesTags);
		}
	};

	const [isMeetingLoading, setIsMeetingLoading] = useState(false);

	const onChangeHandlerMeeting = (filter: string) => {
		setIsMeetingLoading(true);
		setTimeout(function () {
			dispatch(getMeetingsForSelectOption(filter));
			setIsMeetingLoading(false);
		}, 30);
	};

	useEffect(() => {
		if (meeting) {
			setValue('eventSubject', meeting.subject);
			setValue('eventLocation', meeting.location);
			setValue('isOnline', meeting.isOnlineMeeting);
		}
	}, [meeting]);

	const closeTagsModal = (tag: string, color: string) => {
		const newTag = new TagContract(
			undefined,
			tag,
			color,
			user!.graphUserId,
			meeting ? [meeting.iCalUId] : [],
			[],
			[],
			[],
		);
		const updatedTags = [...(tagsMeeting ?? []), newTag];
		setTagsMeeting(updatedTags);
		setTagsModalOpen(false);

		if (meeting) {
			const tags = [
				...meeting.tags,
				new TagContract(undefined, tag, color, user!.graphUserId, [meeting.iCalUId], [], [], []),
			];

			dispatch(
				updateMeetingTags({
					tags,
					selectedMeetingID: meeting.iCalUId,
				}),
			).then((response) => {
				dispatch(updateSelectedMeetingTags(response.payload as TagContract[]));
			});

			return;
		}

		const newTagTB = { tag, color };
		const updatedTagsToBeCreated = [...newTags, newTagTB];
		setNewTags(updatedTagsToBeCreated);
		handleNewTags(updatedTagsToBeCreated);
	};

	const openTagsModal = () => {
		return setTagsModalOpen(!tagsModalOpen);
	};

	// ------ ATTENDEES

	const modalRef = React.useRef<HTMLDivElement>(null);

	const [attendeeMenu, setAttendeeMenu] = React.useState(false);

	useClickOutside(modalRef, () => setAttendeeMenu(!attendeeMenu));

	const [attendees, setAttendees] = React.useState<Attendees[]>(
		meeting
			? meeting.attendees
			: [
					{
						type: 'required',
						emailAddress: {
							name: user?.displayName ? user.displayName : '',
							address: user?.email ? user.email : '',
						},
					},
			  ],
	);

	const [mergedAttendees, setMergedAttendees] = useMergeAttendees({
		userCompany: user?.userCompany,
		contacts: contacts,
		attendees: attendees,
	});

	const handleRemoveAttendee = (email: string) => {
		const newAttendees = attendees.filter((attendee) => attendee.emailAddress.address !== email);
		setValue('attendees', newAttendees);
		trigger('attendees');
		setAttendees(newAttendees);
		handleNewAttendees(newAttendees);
	};

	const handleSelectAttendee = (selectedContact: Items | undefined) => {
		if (selectedContact) {
			const newAttendee = {
				type: 'required',
				emailAddress: {
					name: selectedContact.title,
					address: selectedContact.id ?? '',
				},
			};

			const newAttendees = [...attendees, newAttendee];

			setAttendees(newAttendees);
			handleNewAttendees(newAttendees);
			setAttendeeMenu(!attendeeMenu);
		}
	};

	// -------------

	let timeoutId: any;

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const isEmail = (email: string) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	const onChangeHandler = (filter: string) => {
		setIsLoading(true);
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			dispatch(getCurrentUserContacts(filter)).then((res) => {
				const response = res.payload as ContactsGraphContract[];
				const newContactPR: AttendeeMerge = attendeeMergeMapper(filter, filter);
				setIsLoading(false);

				if (response?.length < 1 && isEmail(filter)) {
					setMergedAttendees([newContactPR]);
				}
			});
		}, 30);
	};

	const IconTeams = 'https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/svg/teams_48x1.svg';

	// --- agenda --- //

	const [datePicker, setDatePicker] = React.useState(false);

	const handleDatePicker = () => {
		setDatePicker(!datePicker);
	};

	const [datePickerEnd, setDatePickerEnd] = React.useState(false);

	const handleDatePickerEnd = () => {
		setDatePickerEnd(!datePickerEnd);
	};

	const [dateSelect, setDateSelect] = React.useState(
		meeting ? new Date(meeting.start) : date ? new Date(date) : new Date(),
	);

	const [dateSelectEnd, setDateSelectEnd] = React.useState(
		meeting ? new Date(meeting.end) : date ? new Date(getRightTimeSlot(new Date(date), 0, 30)) : new Date(),
	);

	const startDate = getStartOfWeekToDate(dateSelect);
	const endDate = getDayPastSevenDays(startDate);
	const dateRange = { startDate, endDate };

	useEffect(() => {
		setValue('eventStart', dateSelect);
		setValue('eventEnd', dateSelectEnd);
	}, []);

	React.useEffect(() => {
		dispatch(getMeetingsForAWeek(dateRange));
		setValue('eventStart', dateSelect);
		setValue('eventEnd', dateSelectEnd);
	}, [dispatch, dateSelect]);

	const [hours, setOpenHours] = React.useState(false);

	const handleHours = () => {
		const toggleHour = !hours;
		setOpenHours(toggleHour);
	};

	const [hoursEnd, setOpenHoursEnd] = React.useState(false);

	const handleHoursEnd = () => {
		setOpenHoursEnd(!hoursEnd);
	};

	const handleDateSelect = (dateSelectPicker: Date) => {
		setDateSelect(dateSelectPicker);
		setDateSelectEnd(
			getRightTimeSlot(getStartOfDayDate(dateSelectPicker), getHour(dateSelectEnd), getMin(dateSelectEnd)),
		);
		setValue('eventStart', dateSelectPicker);
		setValue(
			'eventEnd',
			getRightTimeSlot(getStartOfDayDate(dateSelectPicker), getHour(dateSelectEnd), getMin(dateSelectEnd)),
		);
		handleDatePicker();
	};

	const handleDateSelectEnd = (dateSelectPicker: Date) => {
		setDateSelectEnd(dateSelectPicker);
		setValue('eventEnd', dateSelectPicker);
		handleDatePickerEnd();
	};

	const handleHourSelect = (dateSelectPicker: Date) => {
		const hour = getHour(dateSelect);
		const newHour = getHour(dateSelectPicker);
		const hourDifference = newHour - hour;
		const newEndDate = getRightTimeSlot(dateSelectEnd, hourDifference, 0);
		setDateSelect(dateSelectPicker);
		setDateSelectEnd(newEndDate);
		setValue('eventStart', dateSelectPicker);
		setValue('eventEnd', newEndDate);
		handleHours();
	};

	const handleHourSelectEnd = (dateSelectPicker: Date) => {
		setDateSelectEnd(dateSelectPicker);
		setValue('eventEnd', dateSelectPicker);
		handleHoursEnd();
	};

	// -----

	React.useEffect(() => {
		dispatch(getLastMeetings()).then((res) => {
			const m = res.payload as MeetingContract[];
			dispatch(addLastMeetings(m));
		});
	}, [dispatch]);

	// ___ connected meeting

	const [meetingConnect, setMeetingConnect] = React.useState<string | undefined>(undefined);

	const [series, setSeries] = React.useState<MeetingForSeriesContract[]>();

	React.useEffect(() => {
		if (meeting) {
			dispatch(meetingsForSeries(meeting.iCalUId)).then((res) => {
				const data = res.payload as MeetingForSeriesContract[];
				const sortedData =
					data !== undefined
						? [...data].sort(
								(a, b) =>
									new Date(a.meetingStartDate).getTime() - new Date(b.meetingStartDate).getTime(),
						  )
						: [];
				setSeries(sortedData);
			});
		}
	}, [meeting]);

	const handleChangeProject = (item: Items | undefined) => {
		const selectedProject = projects?.find((project) => project.id === item?.id);

		if (selectedProject) {
			setValue('project', selectedProject);
			return;
		}

		setValue('project', {} as ProjectContract);
	};

	const handleChangeMeeting = (item: Items | undefined) => {
		const selectedMeeting = meetingsOption?.find((itemMeeting) => itemMeeting.iCalUId === item?.id);

		if (selectedMeeting) {
			setValue('meeting', selectedMeeting);
			setValue('serial', true);
			setMeetingConnect(`${selectedMeeting.subject} (${getDateFormatL(selectedMeeting.start)})`);
			return;
		}

		setValue('meeting', {} as MeetingContract);
	};

	const handleTextChange = (content: string) => {
		setValue('bodyContent', content);
		trigger('bodyContent');
	};

	return (
		<FormLayout<FormData>
			formTitle={{
				icon: <IconMeetingCreateModal />,
				title: t('Meeting details'),
			}}
			handleClose={handleClose}
			handleSubmit={handleSubmit}
			onSubmit={onSubmit}
			type={type}>
			<Input
				placeHolder={t('Meeting name') as string}
				defaultValue={{ title: meeting?.subject ?? '' }}
				register={register('eventSubject')}
			/>
			<Input
				placeHolder={t('Meeting location') as string}
				defaultValue={{ title: meeting?.location ?? '' }}
				register={register('eventLocation')}
			/>
			<AttendeesInput
				isLoading={isLoading}
				handleRemoveAttendee={handleRemoveAttendee}
				attendees={attendees.filter((attendee) => attendee.emailAddress.address !== user?.email)}
				resetFieldOnSelect={true}
				placeHolder={t('Invite people') as string}
				onChange={(e) => onChangeHandler(e.currentTarget.value)}
				getValue={handleSelectAttendee}
				renderModal={mergedAttendees.filter((mergedAttendee) => !attendees.some((attendee) => attendee.emailAddress.address === mergedAttendee.email)).map((attendee, index) => ({
					leading: (
						<Avatar size={PersonaSize.size32} name={attendee.name} mail={attendee.email} index={index} />
					),
					id: attendee.email,
					title: attendee.name,
					subTitle: attendee.email,
				}))}
			/>
			{type === FormType.create && (
				<div>
					<div className={styles.linkMeeting}>
						{meeting && prevMeeting && !series ? (
							<div className={styles.connectedMeeting}>
								<Input
									onChange={(e) => onChangeHandlerMeeting(e.currentTarget.value)}
									placeHolder={t('Connected meeting')}
									getValue={handleChangeMeeting}
									defaultValue={{
										title: `${prevMeeting.subject} (${getDateFormatL(
											prevMeeting.createdDateTime,
										)})`,
									}}
									isLoading={isMeetingLoading}
									renderModal={(meetingsOption ?? lastMeetings)?.map((itemMeeting) => ({
										id: itemMeeting.iCalUId,
										title: `${itemMeeting.subject} (${getDateFormatL(
											itemMeeting.createdDateTime,
										)})`,
									}))}
								/>
							</div>
						) : (
							<div className={styles.connectedMeeting}>
								<Input
									onChange={(e) => onChangeHandlerMeeting(e.currentTarget.value)}
									placeHolder={t('Connected meeting')}
									getValue={handleChangeMeeting}
									isLoading={isMeetingLoading}
									defaultValue={
										meeting &&
										series &&
										series
											.filter(
												(meetingS) =>
													new Date(meetingS?.meetingStartDate) < new Date(meeting?.start) &&
													meetingS?.iCalUId !== meeting?.iCalUId,
											)
											.slice(-1)
											.map((meetingS) => ({
												id: meetingS.iCalUId,
												title: `${meetingS.meetingName} (${getDateFormatL(
													meetingS.meetingStartDate,
												)})`,
											}))[0]
									}
									renderModal={(meetingsOption ?? lastMeetings)?.map((itemMeeting) => ({
										id: itemMeeting.iCalUId,
										title: `${itemMeeting.subject} (${getDateFormatL(
											itemMeeting.createdDateTime,
										)})`,
									}))}
								/>
							</div>
						)}
					</div>
				</div>
			)}

			{user && projects && type === FormType.create && (
				<Input
					placeHolder={t<string>('Project')}
					getValue={handleChangeProject}
					defaultValue={{
						title:
							projects.find((project) =>
								meeting
									? project.id === meeting.projectId
									: projectId
									? project.id === projectId
									: project.id === '',
							)?.projectName ?? '',
					}}
					renderModal={projects.map((project) => ({
						id: project.id,
						title: `${project.projectName} (${getDateFormatL(project.createdOn)})`,
					}))}
				/>
			)}

			<div>
				{meeting && meeting.isOnlineMeeting === false ? (
					<>
						<div className={styles.isOnline}>
							<span className={styles.teams}>
								<img src={IconTeams} alt='icon teams' />
								{t('Create a Microsoft Teams meeting')}
							</span>
							<label className={styles.switch}>
								<input type='checkbox' {...register('isOnline')} />
								<span className={styles.slider}></span>
							</label>
						</div>
					</>
				) : !meeting ? (
					<>
						<div className={styles.isOnline}>
							{user && user.provider === AuthenticationProvider.Microsoft && (
								<span className={styles.teams}>
									<img src={IconTeams} alt='icon teams' />
									{t('Create a Microsoft Teams meeting')}
								</span>
							)}

							{user && user.provider === AuthenticationProvider.Google && (
								<span className={styles.teams}>
									<img src={imgGoogle} alt='icon teams' />
									{t('Create a Google Meet meeting')}
								</span>
							)}

							<label className={styles.switch}>
								<input type='checkbox' {...register('isOnline')} />
								<span className={styles.slider}></span>
							</label>
						</div>
					</>
				) : (
					<></>
				)}
			</div>
			<div className={styles.timeslotContainer}>
				<div className={styles.timeslot}>
					<div className={styles.startDate} style={{ borderColor: datePicker ? '#00ad56' : '' }}>
						<div className={styles.startDateButton} onClick={() => handleDatePicker()}>
							<div>
								<span className={styles.dateTitle}>{t('Start dateTime')}</span>
								<div className={styles.datePickerTime}>
									<span>{getDateFormatWithYear(dateSelect.toString())}</span>
								</div>
							</div>

							<div className={styles.icon}>
								<IconMeetings />
							</div>
						</div>
						{datePicker && dateSelect && (
							<MeetingFormDatePickerModal
								date={new Date(dateSelect)}
								handleDatePicker={handleDateSelect}
								close={handleDatePicker}
								meeting={meeting}
								type='start'
							/>
						)}
					</div>
					<div className={styles.startDate} style={{ borderColor: hours ? '#00ad56' : '' }}>
						<div className={styles.startDateButton} onClick={() => handleHours()}>
							<span style={{ fontWeight: 500 }}>{getHourFormat(dateSelect)}</span>
							<div className={styles.icon}>
								<span className={styles.iconColor}>
									<IconDownArrow />
								</span>
							</div>
						</div>

						{hours && (
							<MeetingFormHoursPickerModal
								date={new Date(dateSelect)}
								handleDatePicker={handleHourSelect}
								close={handleHours}
								meeting={meeting}
								type='start'
							/>
						)}
					</div>
				</div>

				<div className={styles.timeslot}>
					<div
						className={styles.startDate}
						style={{ borderColor: datePickerEnd && dateSelectEnd ? '#00ad56' : '' }}>
						<div className={styles.startDateButton} onClick={() => handleDatePickerEnd()}>
							<div>
								<span className={styles.dateTitle}>{t('End dateTime')}</span>
								<div className={styles.datePickerTime}>
									<span>{getDateFormatWithYear(dateSelectEnd.toString())}</span>
								</div>
							</div>

							<div className={styles.icon}>
								<IconMeetings />
							</div>
						</div>

						{datePickerEnd && dateSelectEnd && (
							<MeetingFormDatePickerModal
								date={new Date(dateSelectEnd)}
								handleDatePicker={handleDateSelectEnd}
								close={handleDatePickerEnd}
								meeting={meeting}
								type='end'
							/>
						)}
					</div>

					<div className={styles.startDate} style={{ borderColor: hoursEnd ? '#00ad56' : '' }}>
						<div className={styles.startDateButton} onClick={() => handleHoursEnd()}>
							<span style={{ fontWeight: 500 }}>{getHourFormat(dateSelectEnd)}</span>
							<div className={styles.icon}>
								<span className={styles.iconColor}>
									<IconDownArrow />
								</span>
							</div>
						</div>

						{hoursEnd && (
							<MeetingFormHoursPickerModal
								date={new Date(dateSelectEnd)}
								handleDatePicker={handleHourSelectEnd}
								close={handleHoursEnd}
								meeting={meeting}
								type='end'
							/>
						)}
					</div>
				</div>
			</div>
			<div>
				<div className={styles.containerTags} style={{ borderColor: tagsModalOpen ? '#00ad56' : '' }}>
					<div className={styles.containerTagsButton} onClick={() => openTagsModal()}>
						<div className={styles.tagsWrapper}>
							<p>{t('Tags')}</p>
							<div className={styles.tags}>
								{tagsMeeting?.map((tag) => (
									<Tags key={tag.title} tag={tag} deleteTag={deleteTag} />
								))}
							</div>
						</div>

						<div className={styles.icon}>
							<span className={styles.iconColor}>
								<IconSimplePlus />
							</span>
						</div>
					</div>

					{tagsModalOpen && (
						<TagsFormModal
							close={closeTagsModal}
							handleSimpleClose={openTagsModal}
							type={meeting ? 'meetings' : 'newMeeting'}
						/>
					)}
				</div>
			</div>

			<div className={styles.textEditor}>
				<p>{t('Description')}</p>
				<input
					style={{ display: 'none' }}
					type='text'
					defaultValue={meeting && selectedMeetingInformation ? selectedMeetingInformation?.textMeeting : ''}
					{...register('bodyContent')}
				/>
				<SimpleEditor
					content={meeting && selectedMeetingInformation ? selectedMeetingInformation?.textMeeting : ''}
					onChangeHandlerText={handleTextChange}
				/>
			</div>
		</FormLayout>
	);
};

export default MeetingFormComponent;
