import React from 'react';
import styles from './date-selector.component.module.scss';
import {
	formatedDateTZ,
	getDayLessOneMonth,
	getDayPastOneMonth,
	getDayPastOneWeek,
	getMonthYearFormat,
	getStartOfDayDate,
	getStartOfMonth,
	getStartOfWeek,
} from '../../../../shared/utils/date.util';
import { useTranslation } from 'react-i18next';
import { IconDown, IconUp } from '../../../../shared/utils/icon';
import { useAppDispatch } from '../../../../store/hooks';
import { getMeetingsForAWeek } from '../../../../store/meetings/slice';
import DateSelectorRowComponent from './date-selector-row.component';

interface SelectDateProps {
	selectedDate: Date;
	onDateSelect: (selectDate: Date, type?: string) => void;
	type?: string;
}

const DateSelectorComponent = ({ selectedDate, onDateSelect, type }: SelectDateProps) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const startOfMonth = getStartOfMonth(selectedDate);

	const firstWeekStart = getStartOfWeek(startOfMonth);
	const firstWeek = Array.from({ length: 7 }, (v, i) => firstWeekStart.clone().add(i, 'days').toDate());

	const secondWeeks = getDayPastOneWeek(startOfMonth);
	const secondWeekStart = getStartOfWeek(secondWeeks);
	const secondWeek = Array.from({ length: 7 }, (v, i) => secondWeekStart.clone().add(i, 'days').toDate());

	const thirdWeeks = getDayPastOneWeek(secondWeeks);
	const thirdWeekStart = getStartOfWeek(thirdWeeks);
	const thirdWeek = Array.from({ length: 7 }, (v, i) => thirdWeekStart.clone().add(i, 'days').toDate());

	const fourthWeeks = getDayPastOneWeek(thirdWeeks);
	const fourthWeekStart = getStartOfWeek(fourthWeeks);
	const fourthWeek = Array.from({ length: 7 }, (v, i) => fourthWeekStart.clone().add(i, 'days').toDate());

	const fifthWeeks = getDayPastOneWeek(fourthWeeks);
	const fifthWeekStart = getStartOfWeek(fifthWeeks);
	const fifthWeek = Array.from({ length: 7 }, (v, i) => fifthWeekStart.clone().add(i, 'days').toDate());

	const sixthWeeks = getDayPastOneWeek(fifthWeeks);
	const sixthWeekStart = getStartOfWeek(sixthWeeks);
	const sixthWeek = Array.from({ length: 7 }, (v, i) => sixthWeekStart.clone().add(i, 'days').toDate());

	const handleNextMonth = () => {
		const newDate = getDayPastOneMonth(selectedDate);

		onDateSelect(newDate, 'month');
	};

	const handlePastMonth = () => {
		const newDate = getDayLessOneMonth(selectedDate);

		onDateSelect(newDate, 'month');
	};

	

	return (
		<div className={styles.main}>
			<div className={styles.calendarView}>
				<div className={styles.top}>
					<p>{getMonthYearFormat(selectedDate).charAt(0).toUpperCase() + getMonthYearFormat(selectedDate).slice(1)}</p>
					<div>
						<div className={styles.icon} onClick={() => handlePastMonth()}>
							<IconUp />
						</div>
						<div className={styles.icon} onClick={() => handleNextMonth()}>
							<IconDown />
						</div>
					</div>
				</div>
				<div className={styles.calendar}>
					<div className={styles.header}>
						<div>{t('M')}</div>
						<div>{t('Tu')}</div>
						<div>{t('W')}</div>
						<div>{t('Th')}</div>
						<div>{t('F')}</div>
						<div>{t('St')}</div>
						<div>{t('Sd')}</div>
					</div>

					<DateSelectorRowComponent
						selectedDate={selectedDate}
						onDateSelect={onDateSelect}
						week={firstWeek}
					/>

					<DateSelectorRowComponent
						selectedDate={selectedDate}
						onDateSelect={onDateSelect}
						week={secondWeek}
					/>

					<DateSelectorRowComponent
						selectedDate={selectedDate}
						onDateSelect={onDateSelect}
						week={thirdWeek}
					/>

					<DateSelectorRowComponent
						selectedDate={selectedDate}
						onDateSelect={onDateSelect}
						week={fourthWeek}
					/>

					<DateSelectorRowComponent
						selectedDate={selectedDate}
						onDateSelect={onDateSelect}
						week={fifthWeek}
					/>

					<DateSelectorRowComponent
						selectedDate={selectedDate}
						onDateSelect={onDateSelect}
						week={sixthWeek}
					/>

					<p
						onClick={() => onDateSelect(new Date(formatedDateTZ(getStartOfDayDate(new Date()))))}
						className={styles.goToday}>
						{t('Go to today')}
					</p>
				</div>
			</div>
		</div>
	);
};

export default DateSelectorComponent;
