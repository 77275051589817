import type { ChangeEvent, HTMLProps, KeyboardEvent } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createMarkPositioner, LinkExtension, ShortcutHandlerProps } from 'remirror/extensions';
import {
	CommandButton,
	useActive,
	useAttrs,
	useChainedCommands,
	useCurrentSelection,
	useExtensionEvent,
	useUpdateReason,
} from '@remirror/react';
import { useTranslation } from 'react-i18next';
import styles from '../note-editor.module.scss';
import { NotesContract } from '../../../../store/notes/type';
import { MeetingContract } from '../../../../store/meetings/types';

function useLinkShortcut() {
	const [linkShortcut, setLinkShortcut] = useState<ShortcutHandlerProps | undefined>();
	const [isEditing, setIsEditing] = useState(false);

	useExtensionEvent(
		LinkExtension,
		'onShortcut',
		useCallback(
			(props) => {
				if (!isEditing) {
					setIsEditing(true);
				}

				return setLinkShortcut(props);
			},
			[isEditing],
		),
	);

	return { linkShortcut, isEditing, setIsEditing };
}

function useLinkState() {
	const chain = useChainedCommands();
	const { isEditing, linkShortcut, setIsEditing } = useLinkShortcut();
	const { to, empty } = useCurrentSelection();

	const url = (useAttrs().link()?.href as string) ?? '';
	const [href, setHref] = useState<string>(url);

	const onRemove = useCallback(() => chain.removeLink().focus().run(), [chain]);

	const updateReason = useUpdateReason();

	useEffect(() => {
		if (!isEditing) {
			return;
		}

		if (updateReason.doc || updateReason.selection) {
			setIsEditing(false);
		}
	}, [isEditing, setIsEditing, updateReason.doc, updateReason.selection]);

	useEffect(() => {
		setHref(url);
	}, [url]);

	const submitHref = useCallback(() => {
		setIsEditing(false);
		const range = linkShortcut ?? undefined;

		if (href === '') {
			chain.removeLink();
		} else {
			chain.updateLink({ href, auto: false }, range);
		}

		chain.focus(range?.to ?? to).run();
	}, [setIsEditing, linkShortcut, chain, href, to]);

	const cancelHref = useCallback(() => {
		setIsEditing(false);
	}, [setIsEditing]);

	const clickEdit = useCallback(() => {
		if (empty) {
			chain.selectLink();
		}

		setIsEditing(true);
	}, [chain, empty, setIsEditing]);

	return useMemo(
		() => ({
			href,
			setHref,
			linkShortcut,
			isEditing,
			clickEdit,
			onRemove,
			submitHref,
			cancelHref,
		}),
		[href, linkShortcut, isEditing, clickEdit, onRemove, submitHref, cancelHref],
	);
}

const DelayAutoFocusInput = ({ autoFocus, ...rest }: HTMLProps<HTMLInputElement>) => {
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (!autoFocus) {
			return;
		}

		const frame = window.requestAnimationFrame(() => {
			inputRef.current?.focus();
		});

		return () => {
			window.cancelAnimationFrame(frame);
		};
	}, [autoFocus]);

	return <input style={{ height: '10px' }} ref={inputRef} {...rest} />;
};

export const LinkToolbar = () => {
	const { t } = useTranslation();
	const { isEditing, clickEdit, onRemove, submitHref, href, setHref, cancelHref } = useLinkState();
	const active = useActive();
	const activeLink = active.link();

	const handleClickEdit = useCallback(() => {
		clickEdit();
	}, [clickEdit]);

	return (
		<div className={styles.toolbarLink}>
			{activeLink && !isEditing && (
				<>
					<CommandButton commandName='updateLink' onSelect={handleClickEdit} icon='pencilLine' enabled />
					<CommandButton commandName='removeLink' onSelect={onRemove} icon='linkUnlink' enabled />
				</>
			)}
			{!activeLink && !isEditing && (
				<CommandButton commandName='updateLink' onSelect={handleClickEdit} icon='link' enabled />
			)}
			{isEditing && (
				<div className={styles.linkEditor}>
					<DelayAutoFocusInput
						autoFocus
						placeholder={t('Enter a link') as string}
						onChange={(event: ChangeEvent<HTMLInputElement>) => setHref(event.target.value)}
						value={href}
						onKeyPress={(event: KeyboardEvent<HTMLInputElement>) => {
							const { code } = event;

							if (code === 'Enter') {
								submitHref();
							}

							if (code === 'Escape') {
								cancelHref();
							}
						}}
					/>
				</div>
			)}
		</div>
	);
};
