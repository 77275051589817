import ShimmerAvatar from '../../../../shared/components/shimmers/shimmer-avatar.component';
import ShimmerText from '../../../../shared/components/shimmers/shimmer-text.component';
import styles from './project-details-shimmer.module.scss';
import React from 'react';

const ProjectDetailsShimmer = () => {
    return (
		<div className={styles.projectShimmer}>
			<div className={styles.headerShimmer}>
				<ShimmerText height={20} width={150} />
				<div className={styles.shimmerAvatarContainer}>
					<ShimmerAvatar />
					{Array.from({ length: 2 }).map((_, index) => (
						<div
							key={index}
							style={{
								position: 'absolute',
								top: '50%',
								transform: 'translateY(-50%)',
								left: index === 0 ? '27px' : `${(index + 1) * 25}px`,
								zIndex: `${10 - (index + 1) * 1}`,
							}}>
							<ShimmerAvatar />
						</div>
					))}
				</div>
			</div>
			<div className={styles.shimmerMenu}>
				<ShimmerText height={16} width={72} />
				<ShimmerText height={16} width={84} />
				<ShimmerText height={16} width={72} />
				<ShimmerText height={16} width={72} />
				<ShimmerText height={16} width={72} />
				<ShimmerText height={16} width={72} />
			</div>                
            <div className={styles.bodyShimmer}>
                <div className={styles.shimmerButton}>
                    <ShimmerText height={45} width={255} rounded={0.5}/>
                    <ShimmerText height={45} width={130} rounded={0.5}/>
                </div>
                {Array.from({ length: 2 }).map((_, index) => (
                    <div key={index} className={styles.projectBodyContainer}>
                        <div className={styles.shimmerTypeTitle}>
                            <ShimmerText width={114} height={16} />
                        </div>
                        <div className={styles.dateShimmer}>
                            <ShimmerText width={194} height={20} />
                        </div>
                        <div className={styles.hourShimmer}>
                            <ShimmerText width={134} height={16} />
                        </div>
                        <div className={styles.nameShimmer}>
                            <ShimmerText width={64} height={16} />
                        </div>
                        <ShimmerAvatar width={39} height={39} />
                    </div>
                ))}
            </div>
		</div>
	);
};

export default ProjectDetailsShimmer;