import React, { useState } from 'react';
import styles from './copilot-todos.module.scss';
import { IconLeft } from '../../../../../../../shared/utils/icon';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { getDateFormatWithYear, getTimeFormat } from '../../../../../../../shared/utils/date.util';
import { TodosContract } from '../../../../../../../store/todos/type';
import { RequestStatusType } from '../../../../../../../store/shared/types';
import { Avatar } from '../../../../../../../shared/utils/avatar';
import { addTodosSelectedMeeting, createTodos } from '../../../../../../../store/todos/slice';
import { MistralTodoUpdate } from '../../../../../../../store/recall/type';
import { updateTodoMistral } from '../../../../../../../store/recall/slice';
import CopilotTodosFormModal from './copilot-form-todo.modal';
import CopilotTodosShimmer from '../shimmers/shimmers-todos.component';

export interface Props {
	handleClose: () => void;
}

const CopilotTodosModal = ({ handleClose }: Props) => {
	const { t } = useTranslation();
	const [animation, setAnimation] = useState<boolean>(false);
	const { mistralTodosRequestStatus, todosMistral, dataMeetingBotRequestStatusTranscript } = useAppSelector(
		(state) => state.recall,
	);
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const dispatch = useAppDispatch();
	const { selectMeetingTodos } = useAppSelector((state) => state.todos);
	const [selectedTodos, setSelectedTodos] = useState<TodosContract[]>([]);
	const [modalTodo, setModalTodo] = React.useState('');

	const [modalAssignee, setModalAssignee] = React.useState('');
	const modalRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					setModalAssignee('');
				}, 150);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [setModalAssignee]);

	const handleModalAssignee = (index: string) => {
		setModalAssignee(index);
	};

	const handleChangeAssignee = (name: string, mail: string, todo: TodosContract) => {
		const body: MistralTodoUpdate = {
			id: todo.id,
			newAttMail: mail,
			newAttName: name,
			newTitle: todo.title,
			projectId: todo.projectId,
		};
		dispatch(updateTodoMistral(body));
		setModalAssignee('');
	};

	const handleChangeTtile = (title: string, todo: TodosContract) => {
		const body: MistralTodoUpdate = {
			id: todo.id,
			newAttMail: todo.assigneeDisplayName,
			newAttName: todo.assigneeName,
			newTitle: title,
			projectId: todo.projectId,
		};
		dispatch(updateTodoMistral(body));
	};

	const handleModalForm = (index: string) => {
		setModalTodo(index);
	};

	const handleTodoSelection = (todo: TodosContract) => {
		const todoIndex = selectedTodos.findIndex((selectedTodo) => selectedTodo.id === todo.id);

		if (todoIndex === -1) {
			setSelectedTodos([...selectedTodos, todo]);
		} else {
			const updatedTodos = [...selectedTodos];
			updatedTodos.splice(todoIndex, 1);
			setSelectedTodos(updatedTodos);
		}
	};

	const closeModal = () => {
		setAnimation(true);
		setTimeout(() => {
			handleClose();
			setAnimation(false);
		}, 300);
	};

	const handleCreateNewTodos = async () => {
		if (selectedTodos) {
			for (const element of selectedTodos) {
				await dispatch(createTodos(element)).then((res) => {
					const newTodo = res.payload as TodosContract;
					dispatch(addTodosSelectedMeeting(newTodo));
				});
			}
			closeModal();
		}
	};

	return (
		<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
			<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
				<div className={styles.close} onClick={() => closeModal()}>
					<IconLeft />
					{t('Back')}
				</div>
				{selectedMeeting && (
					<div className={styles.info}>
						<span>{t(getDateFormatWithYear(selectedMeeting.start))}</span>
						<br />
						<span>
							<span>{getTimeFormat(selectedMeeting.start, selectedMeeting.end)}</span>
						</span>
						<h3>{selectedMeeting.subject}</h3>
					</div>
				)}
				<div>
					{dataMeetingBotRequestStatusTranscript.type === RequestStatusType.InProgress && (
						<div>
							<CopilotTodosShimmer />
						</div>
					)}
					{mistralTodosRequestStatus.type === RequestStatusType.InProgress && (
						<>
							<CopilotTodosShimmer />
						</>
					)}
					{mistralTodosRequestStatus.type === RequestStatusType.Failed && (
						<>{mistralTodosRequestStatus.errorMessage}</>
					)}
					{selectMeetingTodos && mistralTodosRequestStatus.type === RequestStatusType.Success && (
						<>
							{' '}
							<div className={styles.todos}>
								{todosMistral?.map((todo, index) => (
									<div className={styles.todoRow} key={index}>
										<div className={styles.check}>
											<input
												type='checkbox'
												style={{ width: '15px', margin: '0' }}
												onChange={() => handleTodoSelection(todo)}
												checked={selectedTodos.some(
													(selectedTodo) => selectedTodo.id === todo.id,
												)}
											/>
										</div>

										<div className={styles.containerAvatar}>
											<div className={styles.avatar} onClick={() => handleModalAssignee(todo.id)}>
												<Avatar
													name={todo.assigneeName}
													mail={todo.assigneeDisplayName}
													index={index}
												/>
											</div>
											{modalAssignee === todo.id && (
												<div className={styles.modalAssignee} ref={modalRef}>
													<div
														className={styles.row}
														onClick={() => handleChangeAssignee('Unasigned', '', todo)}>
														<Avatar name={'Unasigned'} mail={''} index={index} />
														<div>{t('Unasigned')}</div>
													</div>
													{selectedMeeting &&
														selectedMeeting.attendees.map((att) => (
															<div
																key={att.emailAddress.address}
																className={styles.row}
																onClick={() =>
																	handleChangeAssignee(
																		att.emailAddress.name,
																		att.emailAddress.address,
																		todo,
																	)
																}>
																<Avatar
																	name={att.emailAddress.name}
																	mail={att.emailAddress.address}
																	index={index}
																/>
																<div>{att.emailAddress.name}</div>
															</div>
														))}
												</div>
											)}
											{modalTodo === todo.id && (
												<CopilotTodosFormModal handleClose={handleModalForm} todo={todo} />
											)}
										</div>

										<div className={styles.title}>
											<input
												defaultValue={todo.title}
												onChange={(e) => handleChangeTtile(e.target.value, todo)}
											/>
										</div>

										{/* <div className={styles.icon} onClick={() => handleModalForm(todo.id)}>
											<IconEdit />
										</div> */}
									</div>
								))}
							</div>
							<div className={styles.buttons}>
								<p>
									{selectedTodos.length} {t('Todos selected')}
								</p>
								<button onClick={() => handleCreateNewTodos()}>{t('Add')}</button>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default CopilotTodosModal;
