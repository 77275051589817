/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from 'react';
import styles from './project-create.module.scss';
import {
	IconClose,
	IconContact,
	IconAdd,
	IconLock,
	IconMeetingsPage,
	IconProjectModal,
	IconSimplePlus,
} from '../../../shared/utils/icon';
import { AccessRightType, TagContract } from '../../../store/meetings/types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getCurrentUserContacts } from '../../../store/user/slice';
import { ContactsGraphContract } from '../../../store/user/type';
import { Avatar } from '../../../shared/utils/avatar';
import TagsFormModal from '../../meetings/modals/tags-form.modal';
import SimpleEditor from '../../../shared/utils/simple-editor';
import { ProjectContract } from '../../../store/project/type';
import {
	addTagsProject,
	addproject,
	createProjects,
	getPeopleShareProject,
	getProjects,
	postPeopleShareProject,
	setSelectedProject,
} from '../../../store/project/slice';
import { emoticonsUT8 } from '../../../shared/utils/ut8-emoticon';
import { NotificationsType } from '../../../store/notifications/type';
import { SendNotifications } from '../../../store/notifications/slice';
import useClickOutside from '../../../shared/hooks/use-click-outside.hook';
import Input, { Items } from '../../../shared/components/input/input.component';
import AttendeesInput from '../../../shared/components/input/attendees-input.component';
import useMergeAttendees from '../../../shared/hooks/use-merge-attendees.hook';
import { attendeeMergeMapper } from '../../../shared/utils/mappers/attendee-merge.mapper';
import { Attendees } from '../../meetings/modals/meeting-create.modal';
import { PersonaSize } from '@fluentui/react';
import Tags from '../../../shared/components/tags/tags.component';

interface Props {
	close: () => void;
}

const ProjectCreateModal: React.FC<Props> = ({ close }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { user, contacts } = useAppSelector((state) => state.user);

	// STATE

	const [title, setTitle] = React.useState('');
	const [access, setAccess] = React.useState(AccessRightType.INTERN);
	const [selectedAddresses, setSelectedAddresses] = React.useState<Attendees[]>([
			{
				type: 'required',
				emailAddress: {
					name: user?.displayName ? user.displayName : '',
					address: user?.email ? user.email : '',
				},
			},
	],);
	const [emoticon, setEmoticon] = React.useState('');
	const [menuEmoticon, setMenuEmoticon] = React.useState(false);
	const [tagsModalOpen, setTagsModalOpen] = React.useState(false);
	const [tags, setTags] = React.useState<TagContract[]>();
	const [newTags, setNewTags] = React.useState<Array<{ tag: string; color: string }>>([]);
	const [text, setText] = React.useState('');
	const [animation, setAnimation] = useState<boolean>(false);

	// ----

	const handleMenuEmoticon = () => {
		setMenuEmoticon(!menuEmoticon);
	};

	const handleAttendee = (selectedContact: Items | undefined) => {
		if (selectedContact) {
			const newAttendee = {
				type: 'required',
				emailAddress: {
					name: selectedContact.title,
					address: selectedContact.id ?? '',
				},
			};
			setSelectedAddresses([...selectedAddresses, newAttendee]);
		}
	};

	const handleDeletePeople = (email: string) => {
		setSelectedAddresses(selectedAddresses.filter((address) => address.emailAddress.address !== email));
	};

	const handleTextChange = (content: string) => {
		setText(content);
	};
	// --- PEOPLE

	let timeoutId: any;

	const modalFormRef = useRef<HTMLDivElement | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const isEmail = (email: string) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	const [mergedAttendees, setMergedAttendees] = useMergeAttendees({
		userCompany: user?.userCompany,
		contacts: contacts,
	});

	const onChangeHandler = (filter: string) => {
		setIsLoading(true);
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			dispatch(getCurrentUserContacts(filter)).then((res) => {
				setIsLoading(false);
				const response = res.payload as ContactsGraphContract[];
				const newContactPR = attendeeMergeMapper(filter, filter);

				if (response?.length < 1 && isEmail(filter)) {
					setMergedAttendees([newContactPR]);
				}
			});
		}, 200);
	};

	// TAG

	const deleteTag = (tag: TagContract) => {
		if (newTags) {
			const updatedTags = tags?.filter((tagsD) => tagsD !== tag);
			setTags(updatedTags);
			const updatesTags = newTags.filter((tagToDelete) => tagToDelete.tag !== tag.title);
			setNewTags(updatesTags);
		}
	};

	const closeTagsModal = (tag: string, color: string) => {
		if (tags) {
			const newTag = new TagContract(undefined, tag, color, user!.graphUserId, [], [], [], []);
			const updatedTags = [...tags, newTag];
			setTags(updatedTags);
			setTagsModalOpen(false);

			const newTagTB = { tag, color };
			const updatedTagsToBeCreated = [...newTags, newTagTB];
			setNewTags(updatedTagsToBeCreated);
		} else {
			const newTag = new TagContract(undefined, tag, color, user!.graphUserId, [], [], [], []);
			const updatedTags = [newTag];
			setTags(updatedTags);
			setTagsModalOpen(false);

			const newTagTB = { tag, color };
			const updatedTagsToBeCreated = [...newTags, newTagTB];
			setNewTags(updatedTagsToBeCreated);
		}
	};

	const handleClose = () => {
		setAnimation(true);

		setTimeout(() => {
			close();
			setAnimation(false);
		}, 300);
	};

	useClickOutside(modalFormRef, () => handleClose());

	const openTagsModal = () => {
		return setTagsModalOpen(!tagsModalOpen);
	};

	// --- CREATE

	const createNewProject = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		if (user) {
			const body: ProjectContract = {
				id: '',
				projectName: title,
				accessRightType: access,
				graphUserId: user.graphUserId,
				text,
				tags: [],
				emoticon,
				archived: false,
				todoCol: 'To do',
				doingCol: 'Doing',
				blockedCol: 'Blocked',
				doneCol: 'Done',
				createdOn: new Date(),
			};

			dispatch(createProjects(body))
				.then((res) => {
					const newProject = res.payload as ProjectContract;

					const createBodyNotification = (attendeeName: string, attendeeEmail: string) => {
						return {
							id: undefined,
							userFromName: user.displayName,
							userFromMail: user.email,
							userToName: attendeeName,
							userToMail: attendeeEmail,
							type: NotificationsType.NEW_PROJECT,
							noteId: undefined,
							todoId: undefined,
							graphiCalUId: undefined,
							projectId: newProject.id,
							meetingStartDate: undefined,
							itemTitle: newProject.projectName,
							visible: true,
							meetingName: undefined,
						};
					};

					if (access === AccessRightType.INTERN) {
						user.userCompany.forEach((userCompany) => {
							if (user.email !== userCompany.userName) {
								const projectBodyNotification = createBodyNotification(
									userCompany.name,
									userCompany.userName,
								);
								dispatch(SendNotifications(projectBodyNotification));
							}
						});
					}

					if (newTags) {
						newTags.forEach((tag) => {
							const bodyTag = {
								tag: tag.tag,
								color: tag.color,
								projectId: newProject.id,
							};

							dispatch(addTagsProject(bodyTag));
						});
					}

					if (access === AccessRightType.SHARED && selectedAddresses.length !== 0) {
						selectedAddresses.forEach((attendee) => {
							const bodyContact = {
								id: '1',
								username: attendee.emailAddress.name,
								mail: attendee.emailAddress.address,
							};

							dispatch(postPeopleShareProject({ body: bodyContact, projectId: newProject.id }));
						});
					}

					const newBody: ProjectContract = {
						id: newProject.id,
						projectName: newProject.projectName,
						accessRightType: newProject.accessRightType,
						graphUserId: newProject.graphUserId,
						text: newProject.text,
						tags: tags ? tags : [],
						emoticon: newProject.emoticon,
						archived: newProject.archived,
						todoCol: 'To do',
						doingCol: 'Doing',
						blockedCol: 'Blocked',
						doneCol: 'Done',
						createdOn: newProject.createdOn,
					};

					dispatch(addproject(newBody));
					getPeopleShareProject(newProject.id);
					dispatch(setSelectedProject(newBody));
				})

				.then(() => {
					dispatch(getProjects()).then();
					handleClose();
				});
		}
	};

	// --

	const modalRefEmoticon = React.useRef<HTMLDivElement>(null);

	useClickOutside(modalRefEmoticon, () => handleMenuEmoticon());

	return (
		<div className={`${styles.modal} ${animation ? styles.fadeOut : ''}`}>
			<div ref={modalFormRef} className={`${styles.container} ${animation ? styles.slideOut : ''}`}>
				<div className={styles.close} onClick={handleClose}>
					<IconClose />
				</div>
				<h3>
					<IconProjectModal />
					{t('Create a new project')}
				</h3>
				<form>
					<div className={styles.flexAccess}>
						<div
							className={access === AccessRightType.INTERN ? styles.selectAccess : styles.access}
							onClick={() => setAccess(AccessRightType.INTERN)}>
							<IconContact />
							{t('Intern')}
						</div>
						<div
							className={access === AccessRightType.SHARED ? styles.selectAccess : styles.access}
							onClick={() => setAccess(AccessRightType.SHARED)}>
							<IconMeetingsPage />
							{t('Shared')}
						</div>
						<div
							className={access === AccessRightType.ONLYME ? styles.selectAccess : styles.access}
							onClick={() => setAccess(AccessRightType.ONLYME)}>
							<IconLock />
							{t('Private')}
						</div>
					</div>

					<div className={styles.iconZone}>
						{emoticon === '' ? (
							<div className={styles.addEmoticone} onClick={() => handleMenuEmoticon()}>
								<div className={styles.iconPlus}>
									<IconSimplePlus />
								</div>

								{emoticon === '' && <>{t('Select an emoticon')}</>}
							</div>
						) : (
							<div className={styles.iconSelect} onClick={() => handleMenuEmoticon()}>
								<span dangerouslySetInnerHTML={{ __html: emoticon }}></span>
							</div>
						)}

						{menuEmoticon && (
							<div className={styles.modalPeople} ref={modalRefEmoticon}>
								<div className={styles.emoticonBlock}>
									{emoticonsUT8.map((emoticonUT, index) => (
										<div
											key={index}
											className={styles.emoticon}
											onClick={() => {
												setEmoticon(emoticonUT.code);
												handleMenuEmoticon();
											}}>
											{emoticonUT.smiley}
										</div>
									))}
								</div>
							</div>
						)}
					</div>

					<div>
						<Input
							type='text'
							placeHolder={t('Write your title here') as string}
							defaultValue={{ title }}
							onChange={(e) => setTitle(e.currentTarget.value)}
						/>
					</div>

					{access === AccessRightType.SHARED && (
						<AttendeesInput
							isLoading={isLoading}
							handleRemoveAttendee={handleDeletePeople}
							attendees={selectedAddresses.filter(
								(attendee) => attendee.emailAddress.address !== user?.email,
							)}
							resetFieldOnSelect={true}
							placeHolder={t('Invite people') as string}
							onChange={(e) => onChangeHandler(e.currentTarget.value)}
							getValue={handleAttendee}
							renderModal={mergedAttendees.filter((mergedAttendee) => !selectedAddresses.some((attendee) => attendee.emailAddress.address === mergedAttendee.email)).map((attendee, index) => ({
								leading: (
									<Avatar size={PersonaSize.size32} name={attendee.name} mail={attendee.email} index={index} />
								),
								id: attendee.email,
								title: attendee.name,
								subTitle: attendee.email,
							}))}
						/>
					)}

					<div className={styles.containerTags} onClick={() => openTagsModal()}>
						<div className={styles.buttonTags}>
							<div className={styles.tagsWrapper}>
								<p>{t('Tags')}</p>
								<div className={styles.tags}>
									{tags?.map((tag) => (
										<Tags key={tag.title} tag={tag} deleteTag={deleteTag} />
									))}
								</div>
							</div>
							<div className={styles.icon}>
								<IconSimplePlus />
							</div>
						</div>

						{tagsModalOpen && (
							<TagsFormModal
								close={closeTagsModal}
								handleSimpleClose={openTagsModal}
								type={'newProject'}
							/>
						)}
					</div>
					<div className={styles.textEditor}>
						<SimpleEditor content={text} onChangeHandlerText={handleTextChange} />
					</div>
					<div className={styles.buttons}>
						<button type='button' className={styles.cancel} onClick={handleClose}>
							{t('Cancel')}
						</button>
						<button
							className={styles.addBtn}
							disabled={title === '' ? true : false}
							onClick={(e) => createNewProject(e)}>
							<IconSimplePlus />
							{t('Create')}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ProjectCreateModal;
