import React from 'react';
import styles from './project-todos.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
	IconClock,
	IconFilter,
	IconSimplePlus,
	IconTodoCalendarView,
	IconTodoKaban,
	IconTodoListView,
} from '../../../../../shared/utils/icon';
import DetailsTodosFormComponents from './project-todos-form';
import TodosViewList from '../../../../todos/components/todos-view-list';
import TodosViewMeetings from '../../../../todos/components/todos-view-meetings';
import TodosViewSimple from '../../../../todos/components/todos-view-simple';
import TodosViewBoard from './todo-view-board';
import { sortTodosProject } from '../../../../../store/project/slice';
import TodosFilterModal from '../../../../todos/modals/todos.filter';
import { TodosStates } from '../../../../../store/todos/type';
import FilterBtn from '../../../../../shared/components/buttons/filterBtn/filter-btn.component';
import OutlinedButton from '../../../../../shared/components/buttons/outlinedBtn/outlined-btn.component';
import ViewSwitcher from '../../../../../shared/components/viewSwitcher/view-switcher.component';

interface Meeting {
	meetingName: string;
	meetingStartDate: string;
	meetingGraphId: string;
	graphiCalUId: string;
}

const ProjectTodos = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { projectsTodos } = useAppSelector((state) => state.project);

	const storedView = localStorage.getItem('viewTodo');
	const [view, setView] = React.useState(storedView ? storedView : 'Simple');
	const [meetings, setMeetings] = React.useState<Meeting[]>([]);
	const handleGroupChange = (val: string) => {
		localStorage.setItem('viewTodo', val);
		setView(val);
	};

	const [menu, setMenu] = React.useState(false);
	const handleMenu = () => {
		setMenu(!menu);
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	React.useEffect(() => {
		if (projectsTodos) {
			const uniqueMeetings = new Map<string, Meeting>();
			dispatch(sortTodosProject());
			projectsTodos.forEach((todo) => {
				const meetingKey = `${todo.meetingName}-${todo.meetingStartDate}`;
				uniqueMeetings.set(meetingKey, {
					meetingName: todo.meetingName,
					meetingStartDate: todo.meetingStartDate,
					meetingGraphId: todo.meetingGraphId,
					graphiCalUId: todo.graphiCalUId,
				});
			});
			const meetingsArray = [...uniqueMeetings.values()];
			setMeetings(meetingsArray);
		}
	}, [projectsTodos]);

	// ----

	const [showTodo, setShowTodo] = React.useState<boolean>(true);
	const [showDone, setShowDone] = React.useState<boolean>(view === 'Board' ? true : false);
	const [showArchived, setArchived] = React.useState<boolean>(false);
	const [showDoing, setShowDoing] = React.useState<boolean>(true);
	const [showBlock, setShowBlock] = React.useState<boolean>(true);

	const handleShowTodo = (show: boolean) => {
		setShowTodo(show);
	};

	const handleShowDone = (show: boolean) => {
		setShowDone(show);
	};

	const handleShowArchived = (show: boolean) => {
		setArchived(show);
	};

	const handleShowDoing = (show: boolean) => {
		setShowDoing(show);
	};

	const handleShowBlock = (show: boolean) => {
		setShowBlock(show);
	};

	const [showFilterModal, setShowFilterModal] = React.useState(false);
	const handleShowFilterModal = () => {
		setShowFilterModal(!showFilterModal);
	};

	const modalRefFilter = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefFilter.current && !modalRefFilter.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleShowFilterModal();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleShowFilterModal]);

	const trueCount = [showTodo, showDone, showArchived, showDoing, showBlock].filter(Boolean).length;

	return (
		<>
			<div className={styles.over}>
				<div className={styles.top}>
					<div className={styles.flexButton}>
						<OutlinedButton onClick={() => handleMenu()}>
							<IconSimplePlus /> {t('Add new todo')}
						</OutlinedButton>
						<div className={styles.filters}>
							<div className={styles.place}>
								<FilterBtn 
									onClick={() => handleShowFilterModal()} 
									trueCount={trueCount} 
									showFilterModal={showFilterModal} 
								/>
								{showFilterModal && (
									<div className={styles.filterModalWrapper} ref={modalRefFilter}>
										<TodosFilterModal
											showTodo={showTodo}
											showDone={showDone}
											showArchived={showArchived}
											showDoing={showDoing}
											showBlock={showBlock}
											handleShowTodo={handleShowTodo}
											handleShowDone={handleShowDone}
											handleShowArchived={handleShowArchived}
											handleShowDoing={handleShowDoing}
											handleShowBlock={handleShowBlock}
										/>
									</div>
								)}
							</div>
						</div>
					</div>

					<div className={styles.flex}>
						<ViewSwitcher 
							handleGroupChange={handleGroupChange}
							view={view}
						/>
					</div>
				</div>
				<div className={styles.main}>
					<div className={styles.overview}>
						{view === 'List' && projectsTodos && (
							<>
								<div className={styles.mainTodoPage}>
									<TodosViewList
										todosAssignee={projectsTodos.filter((todo) => {
											if (todo.archived && !showArchived) {
												return false;
											}

											if (showTodo && todo.state === TodosStates.TODO) {
												return true;
											}

											if (showDoing && todo.state === TodosStates.DOING) {
												return true;
											}

											if (showDone && todo.state === TodosStates.DONE) {
												return true;
											}

											if (showBlock && todo.state === TodosStates.BLOCKED) {
												return true;
											}

											return false;
										})}
									/>
								</div>
							</>
						)}

						{view === 'Meetings' && projectsTodos && meetings && (
							<>
								<div className={styles.mainTodoPage}>
									<TodosViewMeetings
										todosAssignee={projectsTodos.filter((todo) => {
											if (todo.archived && !showArchived) {
												return false;
											}

											if (showTodo && todo.state === TodosStates.TODO) {
												return true;
											}

											if (showDoing && todo.state === TodosStates.DOING) {
												return true;
											}

											if (showDone && todo.state === TodosStates.DONE) {
												return true;
											}

											if (showBlock && todo.state === TodosStates.BLOCKED) {
												return true;
											}

											return false;
										})}
										meetings={meetings}
									/>
								</div>
							</>
						)}

						{view === 'Simple' && projectsTodos && (
							<>
								<div className={styles.mainTodoPage}>
									<TodosViewSimple
										todosAssignee={projectsTodos.filter((todo) => {
											if (todo.archived && !showArchived) {
												return false;
											}

											if (showTodo && todo.state === TodosStates.TODO) {
												return true;
											}

											if (showDoing && todo.state === TodosStates.DOING) {
												return true;
											}

											if (showDone && todo.state === TodosStates.DONE) {
												return true;
											}

											if (showBlock && todo.state === TodosStates.BLOCKED) {
												return true;
											}

											return false;
										})}
									/>
								</div>
							</>
						)}

						{view === 'Board' && projectsTodos && (
							<>
								<div className={styles.mainTodoBoardPage}>
									<TodosViewBoard
										todosAssignee={projectsTodos.filter((todo) => {
											if (todo.archived && !showArchived) {
												return false;
											}

											if (showTodo && todo.state === TodosStates.TODO) {
												return true;
											}

											if (showDoing && todo.state === TodosStates.DOING) {
												return true;
											}

											if (showDone && todo.state === TodosStates.DONE) {
												return true;
											}

											if (showBlock && todo.state === TodosStates.BLOCKED) {
												return true;
											}

											return false;
										})}
									/>
								</div>
							</>
						)}
					</div>
				</div>
				{menu && (
					<div ref={modalRef}>
						<DetailsTodosFormComponents close={handleMenu} />
					</div>
				)}
			</div>
		</>
	);
};

export default ProjectTodos;
