import React from 'react';
import styles from './project-files.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { FileContract } from '../../../../../store/meetings/types';
import {
	deleteFilesProject,
	deleteProjectFile,
	downloadProjectFile,
	getPeopleShareFile,
} from '../../../../../store/project/slice';
import { toast } from '../../../../../shared/components/modals/toast/toast-manager';
import saveAs from 'file-saver';
import { IconDelete, IconDoc, IconEdit, IconSimplePlus } from '../../../../../shared/utils/icon';
import DeleteConfirmBox from '../../../../../shared/utils/delete-box';
import { Icon } from '@fluentui/react/lib/Icon';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import ProjectFormFiles from './project-files-form.modal';
import ProjectShareFormModal from './project-share-form.modal';
import OutlinedButton from '../../../../../shared/components/buttons/outlinedBtn/outlined-btn.component';

const ProjectFilesComponent = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const modalRef = React.useRef<HTMLDivElement>(null);
	const { selectProject, projectFiles } = useAppSelector((state) => state.project);
	const { user } = useAppSelector((state) => state.user);

	const [openConfirmBox, setOpenConfirmBox] = React.useState(false);
	const [fileToDelete, setFileToDelete] = React.useState<FileContract | undefined>(undefined);

	const [openUpdateAccessRight, setOpenUpdateAccessRight] = React.useState<number | undefined>(undefined);
	const [fileToUpdate, setFileToUpdate] = React.useState<FileContract | undefined>(undefined);

	// ---Function //

	const handleConfirmBox = () => {
		setOpenConfirmBox(!openConfirmBox);
	};

	const handleUpdateFile = (index: number) => {
		setOpenUpdateAccessRight((prev) => (prev === index ? undefined : index));
	};

	const handleDeleteFiles = () => {
		if (fileToDelete && user && selectProject) {
			dispatch(
				deleteProjectFile({
					projectId: selectProject.id,
					fileId: fileToDelete.id,
				}),
			).then(() => {
				handleConfirmBox();
				dispatch(deleteFilesProject(fileToDelete.id));

				toast.show({
					id: user?.graphUserId,
					title: t('File delete success') as string,
					duration: 10000,
					type: 'success',
				});
			});
		}
	};

	// ---------

	const handleFileDeleteClick = (file: FileContract) => {
		setFileToDelete(file);
		handleConfirmBox();
	};

	const handleFileUpdateClick = (file: FileContract, index: number) => {
		dispatch(getPeopleShareFile(file.id)).then(() => {
			setFileToUpdate(file);
			handleUpdateFile(index);
		});
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					setOpenUpdateAccessRight(undefined);
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [modalRef]);

	// --------

	const handleDownloadFile = (fileId: string, fileName: string) => {
		if (selectProject)
			dispatch(downloadProjectFile({ projectId: selectProject.id, fileId })).then((result) => {
				saveAs(result.payload as Blob, fileName);
			});
	};

	const [menu, setMenu] = React.useState(false);
	const handleMenu = () => {
		setMenu(!menu);
	};

	const modalRefForm = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefForm.current && !modalRefForm.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	return (
		<div className={styles.over}>
			<OutlinedButton onClick={() => handleMenu()}>
				<IconSimplePlus /> {t('Add a file')}
			</OutlinedButton>
			<div className={styles.files}>
				{projectFiles &&
					projectFiles.map((file: FileContract, index: number) => {
						const name = file.name.replace(/(https?:\/\/)?(www\.)?/, '');

						return (
							<div key={index}>
								<div className={styles.file}>
									<div>
										{file.type === 'URL' ? (
											<div className={styles.link}>
												<div className={styles.globe}>
													<img
														src={`https://www.google.com/s2/favicons?domain=${name}&sz=${256}`}
														alt=''
													/>
												</div>

												<div className={styles.fileName}>
													<a target='_blank' rel='noreferrer' href={file.location}>
														{name}
													</a>
													<div className={styles.accessRight}>
														<div>
															<p>{t(file.accessRightType)}</p>
														</div>
														{user?.graphUserId === file.graphUserId && (
															<div
																className={styles.iconDown}
																onClick={() => handleFileUpdateClick(file, index)}>
																<IconEdit />
															</div>
														)}
													</div>
												</div>
											</div>
										) : (
											<>
												<Icon
													{...getFileTypeIconProps({
														extension: file?.name.split('.')[1],
														size: 24,
													})}
												/>
												<div className={styles.fileName}>
													<a
														target='_blank'
														rel='noreferrer'
														style={{ cursor: 'pointer' }}
														onClick={() => handleDownloadFile(file.id, file.name)}>
														{file.name}
													</a>
													<div className={styles.accessRight}>
														<div>
															<p>{t(file.accessRightType)}</p>
														</div>
														{user?.graphUserId === file.graphUserId && (
															<div
																className={styles.iconDown}
																onClick={() => handleFileUpdateClick(file, index)}>
																<IconEdit />
															</div>
														)}
													</div>
												</div>
											</>
										)}
									</div>

									{user?.graphUserId === file.graphUserId ? (
										<div className={styles.close} onClick={() => handleFileDeleteClick(file)}>
											<IconDelete />
										</div>
									) : (
										<></>
									)}
								</div>
								{openUpdateAccessRight === index && fileToUpdate && (
									<ProjectShareFormModal file={fileToUpdate} close={handleUpdateFile} />
								)}
							</div>
						);
					})}
			</div>

			{menu && user && (
				<div className={styles.form} ref={modalRefForm}>
					<p>
						<IconDoc /> {t('Add a file')}
					</p>
					<ProjectFormFiles userId={user.graphUserId} close={handleMenu} />
				</div>
			)}

			{openConfirmBox && fileToDelete && (
				<DeleteConfirmBox
					handleDelete={handleDeleteFiles}
					handleConfirmBox={handleConfirmBox}
					message='Are you sure you want to delete this file?'
				/>
			)}
		</div>
	);
};

export default ProjectFilesComponent;
