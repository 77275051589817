import React from 'react';
import styles from './shimmers-summary-styles.module.scss';
import ShimmerText from '../../../../../../../shared/components/shimmers/shimmer-text.component';

const CopilotSummaryShimmer = () => {
	return (
		<div>
			<div className={styles.text}>
				<div className={styles.box}>
					<div className={styles.flex}>
						<ShimmerText height={130} width={300} col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
						<div>
							<ShimmerText height={20} width={130} col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
							<br />
							<ShimmerText height={20} width={130} col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
							<br />
							<ShimmerText height={20} width={130} col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
							<br />
							<ShimmerText height={20} width={130} col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
						</div>
					</div>
					<ShimmerText height={20} width={450} col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
					<br />
					<ShimmerText height={20} width={450} col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
					<br />
					<ShimmerText height={20} width={450} col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
				</div>
				<ShimmerText height={40} width={480} col1='#F7F7F7' col2='#F7F7F7' colVia='white' />
			</div>
		</div>
	);
};

export default CopilotSummaryShimmer;
