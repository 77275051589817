/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unicorn/no-null */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getMeetings, createMeeting, updateMeetingTags } from '../../../store/meetings/slice';
import { PropsModalsCreate } from '../../../shared/types/component-interface';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from '../../../shared/components/modals/toast/toast-manager';
import { RouteSearchParams, RouteUrls } from '../../../routes/routes-config';
import { MeetingContract, TagContract } from '../../../store/meetings/types';
import {
	getDayLessOneDayStart,
	getDayPastOneDayStart,
	getStartOfDay,
	setToMidnightAddOneDay,
} from '../../../shared/utils/date.util';
import { postMeetingsForSeries } from '../../../store/series/slice';
import 'react-tooltip/dist/react-tooltip.css';
import MeetingFormComponent, { FormData } from './meeting-form.component';
import { checkUserFromFivedays } from '../../../store/user/slice';
import MeetingFormModalInvite from './meeting-form-modal-invite';
import { MeetingsProjectContract, NotificationsProjectType, ProjectContract } from '../../../store/project/type';
import { SendNotificationsProject, linkMeetingProject } from '../../../store/project/slice';
import { DataMeetingBotContract } from '../../../store/recall/type';
import {
	createDataMeetingBot,
	deleteDataMeetingBot,
	getDataMeetingBot,
	updateSelectMeetingDataBot,
} from '../../../store/recall/slice';
import { NotificationsType } from '../../../store/notifications/type';
import { SendNotifications } from '../../../store/notifications/slice';
import { FormType } from '../../../shared/components/layouts/form-layout.component';

export type Attendees = {
	type: string;
	emailAddress: {
		name: string;
		address: string;
	};
};

const MeetingCreateModal: React.FC<PropsModalsCreate> = ({ close, date, onDateSelect, isSeries, projectId }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { user } = useAppSelector((state) => state.user);

	const [invite, setInvite] = React.useState(false);
	const [meeting, setMeeting] = React.useState<MeetingContract>();
	const [attendeesNonUser, setAttendeeNonUser] = React.useState<Attendees[]>();

	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	// ------ ATTENDEES

	const [attendees, setAttendees] = React.useState<Attendees[]>([
		{
			type: 'required',
			emailAddress: {
				name: user?.displayName ? user.displayName : '',
				address: user?.email ? user.email : '',
			},
		},
	]);

	const handleNewAttendees = (newAt: Attendees[]) => {
		setAttendees(newAt);
	};

	// -------------

	const [newTags, setNewTags] = React.useState<Array<{ tag: string; color: string }>>([]);

	const handleNewTags = (tags: Array<{ tag: string; color: string }>) => {
		setNewTags(tags);
	};

	const createMeetingBody = (data: FormData) => {
		const startDateUTC = new Date(data.eventStart).toISOString();
		const endDateUTC = new Date(data.eventEnd).toISOString();
		return {
			eventBody: {
				subject: data.eventSubject,
				start: {
					dateTime: startDateUTC,
					timeZone: 'UTC',
				},
				end: {
					dateTime: endDateUTC,
					timeZone: 'UTC',
				},
				location: data.eventLocation,
				attendees,
				isOnlineMeeting: data.isOnline,
				onlineMeetingProvider: 'teamsForBusiness',
				content: data.bodyContent,
				isAllDay: false,
				projectId: data.project.id,
			},
		};
	};

	const sendNotifications = (newMeeting: MeetingContract) => {
		if (newMeeting.attendees && newMeeting.attendees.length > 1) {
			newMeeting.attendees.forEach((attendee) => {
				if (user && user.email !== attendee.emailAddress.address) {
					const bodyNotification = {
						id: undefined,
						userFromName: user.displayName,
						userFromMail: user.email,
						userToName: attendee.emailAddress.name,
						userToMail: attendee.emailAddress.address,
						type: NotificationsType.NEW_MEETING,
						noteId: undefined,
						todoId: undefined,
						graphiCalUId: newMeeting.iCalUId,
						projectId: undefined,
						meetingStartDate: newMeeting.start,
						itemTitle: newMeeting.subject,
						visible: true,
						meetingName: newMeeting.subject,
					};

					dispatch(SendNotifications(bodyNotification));
				}
			});
		}
	};

	const linkMeetingToProject = (newMeeting: MeetingContract, data: FormData) => {
		const attendeeEmails = newMeeting
			? newMeeting.attendees.map((attendee) => attendee.emailAddress.address.toLowerCase())
			: [];

		const bodyMeetingProject: MeetingsProjectContract = {
			id: '',
			projectId: data.project.id,
			graphiCalUId: newMeeting.iCalUId,
			meetingName: newMeeting.subject,
			meetingStartDate: newMeeting.start,
			meetingEndDate: newMeeting.end,
			meetingAttendees: attendeeEmails,
			tags: [],
		};

		dispatch(linkMeetingProject({ body: bodyMeetingProject, projectId: data.project.id }));

		if (user && attendeeEmails && data.project.id) {
			const bodyNotification = {
				id: '',
				userFromName: user.name,
				userFromMail: user.email,
				type: NotificationsProjectType.NEW_MEETING,
				noteId: '',
				todoId: '',
				todoDueDate: '',
				itemTitle: '',
				graphiCalUId: newMeeting.iCalUId,
				meetingStartDate: newMeeting.start,
				meetingEndDate: newMeeting.end,
				meetingName: newMeeting.subject,
				meetingAttendees: attendeeEmails,
				projectId: data.project.id,
				userSeenNotif: [],
				todoAssignee: '',
			};

			dispatch(SendNotificationsProject(bodyNotification));
		}
	};

	const updateTags = (newMeeting: MeetingContract) => {
		newTags.forEach((tag) => {
			const tags = [
				...newMeeting.tags,
				new TagContract(undefined, tag.tag, tag.color, user!.graphUserId, [newMeeting.iCalUId], [], [], []),
			];

			dispatch(updateMeetingTags({ tags, selectedMeetingID: newMeeting.iCalUId }));
		});
	};

	const createSeries = async (newMeeting: MeetingContract, data: FormData) => {
		const bodySerie = {
			prevGraphiCalUId: data.meeting.iCalUId,
			prevMeetingStartDate: data.meeting.start,
			prevMeetingName: data.meeting.subject,
			nextGraphiCalUId: newMeeting.iCalUId,
			nextMeetingStartDate: newMeeting.start,
			nextMeetingName: newMeeting.subject,
		};

		await dispatch(postMeetingsForSeries(bodySerie));
	};

	const onSubmit = async (data: FormData) => {
		if (data.bodyContent !== undefined) {
			const body = createMeetingBody(data);

			const startDateUTC = new Date(data.eventStart).toISOString();
			const startDate = getDayLessOneDayStart(new Date(startDateUTC));
			const endDate = getDayPastOneDayStart(new Date(startDateUTC));

			const dateRange = { startDate, endDate };

			const nonUserAttendees: Attendees[] = [];

			attendees.forEach((attendee) => {
				const address = attendee.emailAddress.address;

				dispatch(checkUserFromFivedays(address)).then((res) => {
					if (res.payload === false) {
						nonUserAttendees.push(attendee);
					}
				});
			});

			const result = await dispatch(createMeeting(body));
			const newMeeting = result.payload as MeetingContract;

			if (user) {
				sendNotifications(newMeeting);
				if (data.project) linkMeetingToProject(newMeeting, data);
				if (newTags) updateTags(newMeeting);
			}

			if (data.meeting && data.serial === true) {
				await createSeries(newMeeting, data);

				const ID = (result.payload as MeetingContract).iCalUId;
				const dateEvent = (result.payload as MeetingContract).start;
				dispatch(getMeetings(dateRange));
				onDateSelect(new Date(startDateUTC));
				close();
				const params: any = {};
				params[RouteSearchParams.Date] = getStartOfDay(dateEvent);
				params[RouteSearchParams.MeetingId] = ID;

				navigate(`${RouteUrls.Meetings}?${new URLSearchParams(params).toString()}`);

				toast.show({
					id: user?.graphUserId,
					title: t('Meeting create success') as string,
					duration: 10000,
					type: 'success',
				});
			} else {
				const ID = (result.payload as MeetingContract).iCalUId;
				const dateEvent = (result.payload as MeetingContract).start;
				dispatch(getMeetings(dateRange));
				onDateSelect(new Date(startDateUTC));
				setMeeting(result.payload as MeetingContract);
				setAttendeeNonUser(nonUserAttendees);

				if (nonUserAttendees && nonUserAttendees.length > 0) {
					setInvite(true);
				} else {
					close();
					const params: any = {};
					params[RouteSearchParams.Date] = getStartOfDay(dateEvent);
					params[RouteSearchParams.MeetingId] = ID;

					navigate(`${RouteUrls.Meetings}?${new URLSearchParams(params).toString()}`);
				}

				toast.show({
					id: user?.graphUserId,
					title: t('Meeting create success') as string,
					duration: 10000,
					type: 'success',
				});
			}
		}
	};

	const handleClose = () => {
		close();
		if (location.pathname.includes('/meetings')) {
			navigate(`${RouteUrls.Meetings}`);
		} else if (location.pathname.includes('/calendar')) {
			navigate(`${RouteUrls.Calendars}`);
		}
	};

	const handleCloseInvite = (start: string, id: string) => {
		close();
		const params: any = {};
		params[RouteSearchParams.Date] = getStartOfDay(start);
		params[RouteSearchParams.MeetingId] = id;
		navigate(`${RouteUrls.Meetings}?${new URLSearchParams(params).toString()}`);
	};

	return (
		<>
			{!invite ? (
				<MeetingFormComponent
					onSubmit={onSubmit}
					handleClose={close}
					date={date}
					handleNewAttendees={handleNewAttendees}
					type={FormType.create}
					handleNewTags={handleNewTags}
					projectId={projectId}
				/>
			) : (
				meeting &&
				attendeesNonUser && (
					<MeetingFormModalInvite
						meeting={meeting}
						nonUserAttendees={attendeesNonUser}
						close={handleCloseInvite}
					/>
				)
			)}
		</>
	);
};

export default MeetingCreateModal;
