import React, { useState } from 'react';
import styles from './meeting-details.component.module.scss';
import MeetingDetailsHeader from './meetingDetailsHeader/meetings-details-header';
import MeetingDetailsBody from './meetingDetailsBody/meetings-details-body';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { MeetingContract, TagContract } from '../../../../store/meetings/types';
import {
	updateSelectedMeetingTags,
	updateMeetingTags,
	setPrevMeeting,
	getMeetingInformations,
	getMeeting,
	setPrevMeetings,
} from '../../../../store/meetings/slice';
import { getSelectMeetingTodos, getSelectMeetingTodosPrev } from '../../../../store/todos/slice';
import {
	getAllNoteForAMeeting,
	getAllNoteForAPrecedentMeeting,
	setPrevMeetingsNotes,
} from '../../../../store/notes/slice';
import {
	getAgendaForAMeetingShared,
	getAllNoteForAMeetingShared,
	getAllNoteForAMeetingSharedPrev,
	getFilesForAMeetingShared,
	getMeetingsCollaborators,
	getSelectMeetingTodosForShared,
	getSelectMeetingTodosForSharedPrev,
} from '../../../../store/sharing/slice';
import { getAllAgendaItemForAMeeting, getAllAgendaItems } from '../../../../store/agenda/slice';
import { IconLeftChev } from '../../../../shared/utils/icon';
import { useParams } from 'react-router-dom';
import { getCommentsForANoteForMeetings } from '../../../../store/comments/slice';
import { MeetingForSeriesContract } from '../../../../store/series/type';
import { meetingsForSeries } from '../../../../store/series/slice';
import { getOneProject } from '../../../../store/project/slice';
import { NotesContract } from '../../../../store/notes/type';
import { getDataMeetingBot, updateSelectMeetingDataBot } from '../../../../store/recall/slice';
import Loader from '../../../../shared/components/loader/loader.component';
import MeetingDetailsShimmer from './meetingDetailsShimmers/meeting-details-shimmer.component';

interface props {
	toggle?: () => void;
	isLoading: boolean;
	setIsLoading: (param: boolean) => void;
}

const MeetingDetailsComponent = ({ toggle, isLoading, setIsLoading }: props) => {
	const dispatch = useAppDispatch();
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const { user } = useAppSelector((state) => state.user);

	const params = useParams();
	const shareId = params.shareID;

	const storedAccessMail = localStorage.getItem(`accessMail/${shareId}`);

	const handleTagsChanged = (tags: TagContract[]) => {
		selectedMeeting &&
			user &&
			dispatch(
				updateMeetingTags({
					tags,
					selectedMeetingID: selectedMeeting?.iCalUId,
				}),
			).then((response) => {
				dispatch(updateSelectedMeetingTags(response.payload as TagContract[]));
			});
	};

	const [series, setSeries] = React.useState<MeetingForSeriesContract[]>();

	React.useEffect(() => {
		const fetchData = async () => {
			if (selectedMeeting) {
				if (user) {
					await dispatch(getSelectMeetingTodos(selectedMeeting.iCalUId));
					await dispatch(getAllNoteForAMeeting(selectedMeeting.iCalUId));
					await dispatch(getAllAgendaItemForAMeeting(selectedMeeting.iCalUId));
					await dispatch(getMeetingsCollaborators(selectedMeeting.iCalUId));
					await dispatch(getAllAgendaItems());
					// dispatch(getCommentsForANoteForMeetings(selectedMeeting.iCalUId));

					await dispatch(getDataMeetingBot(selectedMeeting.iCalUId)).then((res) => {
						const data = res.payload;

						if (data === false) {
							dispatch(updateSelectMeetingDataBot(undefined));
						}
					});

					await dispatch(meetingsForSeries(selectedMeeting.iCalUId)).then((res) => {
						const data = res.payload as MeetingForSeriesContract[];
						const sortedData =
							data !== undefined
								? [...data].sort(
										(a, b) =>
											new Date(a.meetingStartDate).getTime() -
											new Date(b.meetingStartDate).getTime(),
								  )
								: [];
						setSeries(sortedData);
					});

					await dispatch(getMeetingInformations(selectedMeeting.iCalUId));
					if (selectedMeeting.projectId) await dispatch(getOneProject(selectedMeeting.projectId));
					setIsLoading(false);
				} else {
					await dispatch(getSelectMeetingTodosForShared(selectedMeeting.iCalUId));
					await dispatch(
						getAllNoteForAMeetingShared({
							iCalUId: selectedMeeting.iCalUId,
							userId: storedAccessMail ? storedAccessMail : '',
						}),
					);
					await dispatch(getAgendaForAMeetingShared(selectedMeeting.iCalUId));
					await dispatch(
						getFilesForAMeetingShared({
							iCalUId: selectedMeeting.iCalUId,
							userId: storedAccessMail ? storedAccessMail : '',
						}),
					);

					await dispatch(getMeetingsCollaborators(selectedMeeting.iCalUId));
					// dispatch(getCommentsForANoteForMeetings(selectedMeeting.iCalUId));
					await dispatch(getMeetingInformations(selectedMeeting.iCalUId));

					await dispatch(getDataMeetingBot(selectedMeeting.iCalUId));
					setIsLoading(false);
				}
			}
		};
		fetchData();
	}, [selectedMeeting]);

	const handleCalendar = () => {
		if (toggle) toggle();
	};

	const { meetingsForSeriesState } = useAppSelector((state) => state.meetings);
	const { shareMeetingDataPrev } = useAppSelector((state) => state.sharing);

	// React.useEffect(() => {
	// 	if (selectedMeeting && user) {
	// 		dispatch(meetingsForSeries(selectedMeeting.iCalUId)).then((res) => {
	// 			const data = res.payload as MeetingForSeriesContract[];
	// 			const sortedData =
	// 				data !== undefined
	// 					? [...data].sort(
	// 							(a, b) =>
	// 								new Date(a.meetingStartDate).getTime() - new Date(b.meetingStartDate).getTime(),
	// 					  )
	// 					: [];
	// 			setSeries(sortedData);
	// 		});
	// 	}
	// }, [selectedMeeting]);

	React.useEffect(() => {
		if (selectedMeeting && selectedMeeting?.type === 'singleInstance' && series && user) {
			console.log('hello');
			const prevMeeting = series
				.filter(
					(meeting) =>
						new Date(meeting.meetingStartDate) < new Date(selectedMeeting?.start) &&
						meeting.iCalUId !== selectedMeeting.iCalUId,
				)
				.slice(-1)[0];

			const prevMeetings = series.filter(
				(meeting) =>
					new Date(meeting.meetingStartDate) < new Date(selectedMeeting?.start) &&
					meeting.iCalUId !== selectedMeeting.iCalUId,
			);

			console.log(prevMeeting);

			if (prevMeeting) {
				dispatch(getMeeting(prevMeeting.iCalUId)).then((res) => {
					dispatch(setPrevMeeting(res.payload as MeetingContract));
				});
			} else {
				dispatch(setPrevMeeting(undefined));
			}

			if (prevMeetings) {
				dispatch(setPrevMeetings(prevMeetings));
			} else {
				dispatch(setPrevMeetings(undefined));
			}
		} else if (selectedMeeting && selectedMeeting.type !== 'singleInstance' && meetingsForSeriesState) {
			const prevMeeting = meetingsForSeriesState
				.filter((meeting) => new Date(meeting.start) < new Date(selectedMeeting?.start))
				.slice(-1)[0];

			const prevMeetings = meetingsForSeriesState.filter(
				(meeting) => new Date(meeting.start) < new Date(selectedMeeting?.start),
			);

			if (prevMeeting) {
				dispatch(setPrevMeeting(prevMeeting));
			} else {
				dispatch(setPrevMeeting(undefined));
			}

			if (prevMeetings) {
				dispatch(setPrevMeetings(prevMeetings));
			} else {
				dispatch(setPrevMeetings(undefined));
			}
		}
	}, [series]);

	React.useEffect(() => {
		if (!user && shareMeetingDataPrev) {
			const prevMeeting = shareMeetingDataPrev
				.filter((meeting) => meeting.iCalUId !== selectedMeeting?.iCalUId)
				.slice(-1)[0];

			const prevMeetings = shareMeetingDataPrev.filter((meeting) => meeting.iCalUId !== selectedMeeting?.iCalUId);

			if (prevMeeting) {
				dispatch(setPrevMeeting(prevMeeting));
			} else {
				dispatch(setPrevMeeting(undefined));
			}

			if (prevMeetings) {
				dispatch(setPrevMeetings(prevMeetings));
			} else {
				dispatch(setPrevMeetings(undefined));
			}
		}
	}, [shareMeetingDataPrev]);

	const { prevMeetings, prevMeeting } = useAppSelector((state) => state.meetings);
	const [notes, setNotes] = React.useState<NotesContract[]>([]);

	const fetchNotes = async (meeting: MeetingContract | MeetingForSeriesContract) => {
		try {
			setNotes([]);
			const res = await dispatch(getAllNoteForAPrecedentMeeting(meeting.iCalUId));
			const note = res.payload as NotesContract;
			setNotes((prevNotes) => [...prevNotes, note]);
			return note;
		} catch (error) {
			console.error('Error fetching notes:', error);
			throw error;
		}
	};

	const fetchNotesInvite = async (meeting: MeetingContract | MeetingForSeriesContract, mail: string) => {
		try {
			setNotes([]);
			const res = await dispatch(getAllNoteForAMeetingSharedPrev({ iCalUId: meeting.iCalUId, userId: mail }));
			const note = res.payload as NotesContract;
			setNotes((prevNotes) => [...prevNotes, note]);
			return note;
		} catch (error) {
			console.error('Error fetching notes:', error);
			throw error;
		}
	};

	React.useEffect(() => {
		dispatch(setPrevMeetingsNotes(undefined));
		if (selectedMeeting && prevMeetings) {
			if (prevMeetings.length > 0) {
				if (user) {
					const fetchNotesPromises = prevMeetings.map(async (meeting) => {
						return fetchNotes(meeting);
					});

					Promise.all(fetchNotesPromises)
						.then((resolvedNotes) => {
							const flatNotes = resolvedNotes.flat();

							if (flatNotes.length > 0) {
								dispatch(setPrevMeetingsNotes(flatNotes));
							} else {
								dispatch(setPrevMeetingsNotes(undefined));
							}
						})
						.catch((error) => {
							console.error('Error fetching notes:', error);
						});
				} else if (storedAccessMail) {
					const fetchNotesPromises = prevMeetings.map(async (meeting) => {
						return fetchNotesInvite(meeting, storedAccessMail);
					});

					Promise.all(fetchNotesPromises)
						.then((resolvedNotes) => {
							const flatNotes = resolvedNotes.flat();

							if (flatNotes.length > 0) {
								dispatch(setPrevMeetingsNotes(flatNotes));
							} else {
								dispatch(setPrevMeetingsNotes(undefined));
							}
						})
						.catch((error) => {
							console.error('Error fetching notes:', error);
						});
				}
			} else {
				dispatch(setPrevMeetingsNotes(undefined));
			}
		} else {
			dispatch(setPrevMeetingsNotes(undefined));
		}
	}, [prevMeetings, selectedMeeting]);

	React.useEffect(() => {
		if (prevMeeting) {
			if (user) {
				dispatch(getSelectMeetingTodosPrev(prevMeeting.iCalUId));
			} else if (storedAccessMail) {
				dispatch(getSelectMeetingTodosForSharedPrev(prevMeeting.iCalUId));
			}
		}
	}, [selectedMeeting]);

	return (
		<div className={styles.main}>
			<div className={styles.meetingDetails}>
				<div className={styles.flex}>
					<div className={styles.icon} onClick={() => handleCalendar()}>
						<IconLeftChev />
					</div>
					<p>{selectedMeeting?.subject}</p>
				</div>

				{isLoading ? (
					<MeetingDetailsShimmer />
				) : (
					<>
						<MeetingDetailsHeader handleTagsChanged={handleTagsChanged} selectedMeeting={selectedMeeting} />
						<MeetingDetailsBody handleTagsChanged={handleTagsChanged} />
					</>
				)}
			</div>
		</div>
	);
};

export default MeetingDetailsComponent;
