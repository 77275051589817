import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../store/hooks';
import ProjectDetailsHeader from './header/project-details-header';
import styles from './project-details.module.scss';
import { Tab } from '../../../shared/types/component-interface';
import ProjectDescription from './tabs/description/project-description';
import ProjectLastInfo from './tabs/lastinfo/project-info';
import ProjectMeetings from './tabs/meetings/project-meetings';
import ProjectNotes from './tabs/notes/project-notes';
import ProjectTodos from './tabs/todos/project-todos';
import { IconDownChev, IconLeftChev, IconUpChev } from '../../../shared/utils/icon';
import ProjectFilesComponent from './tabs/files/project-files.component';
import { RequestStatusType } from '../../../store/shared/types';
import { TodosStates } from '../../../store/todos/type';
import Loader from '../../../shared/components/loader/loader.component';
import ProjectDetailsShimmer from './shimmers/project-details-shimmer.component';

interface Props {
	showList: () => void;
	isLoading: boolean,
	activeTabIndex: number,
	setActiveTabIndex: (params: number) => void,
}

const ProjectDetails: React.FC<Props> = ({ showList, isLoading, activeTabIndex, setActiveTabIndex }) => {
	const { t } = useTranslation();

	const {
		selectProject,
		projectsMeetings,
		projectsNotes,
		projectsTodos,
		projectNotifications,
		projectFiles,
		projectsNotesRequestStatus,
	} = useAppSelector((state) => state.project);
	const { user } = useAppSelector((state) => state.user);
	// const [activeTabIndex, setActiveTabIndex] = React.useState(1);

	const handleActiveTab = async (nbr: number, noteId?: string) => {
		setActiveTabIndex(nbr);
	};

	const [tabs] = React.useState<Tab[]>([
		{
			label: t('Description'),
			component: <ProjectDescription />,
		},
		{ label: t('Last infos'), component: <ProjectLastInfo switchTab={handleActiveTab} /> },
		{
			label: t('Meetings'),
			component: <ProjectMeetings />,
		},
		{
			label: t('Notes'),
			component: <ProjectNotes />,
		},
		{
			label: t('Todos'),
			component: <ProjectTodos />,
		},
		{
			label: t('Files'),
			component: <ProjectFilesComponent />,
		},
	]);

	// MOBILE //

	const [dropdownOptions, setDropdownOptions] = React.useState(false);

	const handleDropDownOptions = () => {
		setDropdownOptions(!dropdownOptions);
	};

	const [Options, setOptions] = React.useState('Description');

	const handleNewOption = (data: string) => {
		setOptions(data);
		handleDropDownOptions();
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					if (dropdownOptions) handleDropDownOptions();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleDropDownOptions]);

	return (
		<div className={styles.main}>
			<div className={styles.iconMob} onClick={() => showList()}>
				<IconLeftChev />
			</div>
			{isLoading && (
				<>
					<ProjectDetailsShimmer />
				</>
			)}
			{projectsNotesRequestStatus.type === RequestStatusType.Failed && (
				<>{projectsNotesRequestStatus.errorMessage}</>
			)}
			{selectProject && user && !isLoading && (
				<>
					<ProjectDetailsHeader />
					<div className={styles.body}>
						<div className={styles.tabs}>
							{tabs.map((tab, index) => (
								<div
									className={styles.tabsWrapper}
									key={index}
									onClick={() => setActiveTabIndex(index)}
									style={{ cursor: 'pointer' }}>
									<a className={index === activeTabIndex ? styles.activeTab : ''}>{tab.label}</a>
									{index === 1 && (
										<span
											className={index === activeTabIndex ? styles.activeNumber : styles.number}>
											{projectNotifications &&
												projectNotifications.filter(
													(notification) =>
														!notification.userSeenNotif.includes(user.userName),
												).length}
										</span>
									)}
									{index === 2 && (
										<span
											className={index === activeTabIndex ? styles.activeNumber : styles.number}>
											{projectsMeetings && projectsMeetings.length}
										</span>
									)}
									{index === 3 && (
										<span
											className={index === activeTabIndex ? styles.activeNumber : styles.number}>
											{projectsNotes &&
												projectsNotes.filter((note) => note.archived === false).length}
										</span>
									)}

									{index === 4 && (
										<span
											className={index === activeTabIndex ? styles.activeNumber : styles.number}>
											{projectsTodos &&
												projectsTodos
													.filter((todo) => todo.archived === false)
													.filter((todo) => todo.state !== TodosStates.DONE).length}
										</span>
									)}

									{index === 5 && (
										<span
											className={index === activeTabIndex ? styles.activeNumber : styles.number}>
											{projectFiles && projectFiles.length}
										</span>
									)}
								</div>
							))}
						</div>
						<div className={styles.activeTabsWrapper}>{tabs[activeTabIndex].component}</div>
					</div>
					<div className={styles.mobileBody}>
						<div className={styles.menuMobile}>
							<div className={styles.selectCustom}>
								<button type='button' onClick={() => handleDropDownOptions()}>
									{t(Options)}
									{!dropdownOptions ? <IconDownChev /> : <IconUpChev />}
								</button>
								{dropdownOptions && (
									<div className={styles.dropdown} ref={modalRef}>
										<p onClick={() => handleNewOption('Description')}>{t('Description')}</p>

										<p onClick={() => handleNewOption('LastInfo')}>{t('LastInfo')}</p>

										<p onClick={() => handleNewOption('Meetings')}>{t('Meetings')}</p>

										<p onClick={() => handleNewOption('Notes')}>{t('Notes')}</p>

										<p onClick={() => handleNewOption('Todos')}>{t('Todos')}</p>

										<p onClick={() => handleNewOption('Files')}>{t('Files')}</p>
									</div>
								)}
							</div>
						</div>
						<div>
							{Options === 'Description' ? (
								<ProjectDescription />
							) : Options === 'LastInfo' ? (
								<ProjectLastInfo switchTab={handleActiveTab} />
							) : Options === 'Meetings' ? (
								<ProjectMeetings />
							) : Options === 'Notes' ? (
								<ProjectNotes />
							) : Options === 'Todos' ? (
								<ProjectTodos />
							) : Options === 'Files' ? (
								<ProjectFilesComponent />
							) : (
								<></>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default ProjectDetails;
