import { extractDomainWithExt } from './domainext';

export const getFaviconUrl = (email: string) => {
	const domainExt = extractDomainWithExt(email);

	const finalDomain =
		domainExt === 'gmail.com' ? 'google.com' : domainExt === 'coophub.eu' ? 'https://www.coophub.eu' : domainExt;

	const faviconUrl = `https://www.google.com/s2/favicons?domain=${finalDomain}&sz=256`;

	return faviconUrl;
};
