import React from 'react';
import styles from './meetings-files.component.module.scss';
import { useTranslation } from 'react-i18next';
import { Icon } from '@fluentui/react/lib/Icon';
import { FileContract, MeetingContract } from '../../../../../../store/meetings/types';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { deleteFilesSuccess, deleteMeetingFile, downloadMeetingFile } from '../../../../../../store/meetings/slice';

import { initializeFileTypeIcons, getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { toast } from '../../../../../../shared/components/modals/toast/toast-manager';
import { IconDelete, IconDoc, IconEdit, IconSimplePlus } from '../../../../../../shared/utils/icon';
import { saveAs } from 'file-saver';
import {
	deleteMeetingFileShared,
	downloadMeetingFileShared,
	getFilesForAMeetingShared,
	getPeopleShareFileShare,
} from '../../../../../../store/sharing/slice';
import DeleteConfirmBox from '../../../../../../shared/utils/delete-box';
import MeetingsFormFiles from './meetings-files-form.component';
import MeetingFilesShareFormModal from './meetings-files-share-form';
import { getPeopleShareFile } from '../../../../../../store/project/slice';
import { defaultStyles } from 'react-file-icon';
import OutlinedButton from '../../../../../../shared/components/buttons/outlinedBtn/outlined-btn.component';

initializeFileTypeIcons();

interface Props {
	mail: string;
	userId: string;
	selectedMeeting: MeetingContract;
	userCompagnyName: string;
	files: FileContract[];
}

const MeetingsFilesListComponent: React.FC<Props> = ({ mail, userId, selectedMeeting, userCompagnyName, files }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const modalRef = React.useRef<HTMLDivElement>(null);

	const { user } = useAppSelector((state) => state.user);

	const [openConfirmBox, setOpenConfirmBox] = React.useState(false);
	const [fileToDelete, setFileToDelete] = React.useState<FileContract | undefined>(undefined);

	const [openUpdateAccessRight, setOpenUpdateAccessRight] = React.useState<number | undefined>(undefined);
	const [fileToUpdate, setFileToUpdate] = React.useState<FileContract | undefined>(undefined);

	// ---Function //

	const handleConfirmBox = () => {
		setOpenConfirmBox(!openConfirmBox);
	};

	const handleUpdateFile = (index: number) => {
		setOpenUpdateAccessRight((prev) => (prev === index ? undefined : index));
	};

	const handleDeleteFiles = () => {
		if (fileToDelete) {
			if (user) {
				dispatch(
					deleteMeetingFile({
						selectedMeetingID: selectedMeeting!.graphEventId,
						fileId: fileToDelete.id,
					}),
				).then(() => {
					dispatch(deleteFilesSuccess(fileToDelete.id));
					handleConfirmBox();

					toast.show({
						id: user?.graphUserId,
						title: t('File delete success') as string,
						duration: 10000,
						type: 'success',
					});
				});
			} else {
				dispatch(
					deleteMeetingFileShared({
						selectedMeetingID: selectedMeeting.iCalUId,
						fileId: fileToDelete.id,
						userId,
					}),
				).then(() => {
					handleConfirmBox();
					dispatch(getFilesForAMeetingShared({ iCalUId: selectedMeeting.iCalUId, userId: mail }));
					toast.show({
						id: userId,
						title: t('File delete success') as string,
						duration: 10000,
						type: 'success',
					});
				});
			}
		}
	};

	// ---------

	const handleFileDeleteClick = (file: FileContract) => {
		setFileToDelete(file);
		handleConfirmBox();
	};

	const handleFileUpdateClick = (file: FileContract, index: number) => {
		if (user) {
			dispatch(getPeopleShareFile(file.id)).then(() => {
				setFileToUpdate(file);
				handleUpdateFile(index);
			});
		} else {
			dispatch(getPeopleShareFileShare(file.id)).then(() => {
				setFileToUpdate(file);
				handleUpdateFile(index);
			});
		}
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					setOpenUpdateAccessRight(undefined);
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [modalRef]);

	// --------

	const handleDownloadFile = (fileId: string, fileName: string) => {
		dispatch(downloadMeetingFile({ selectedMeetingID: selectedMeeting.iCalUId, fileId })).then((result) => {
			saveAs(result.payload as Blob, fileName);
		});
	};

	const handleDownloadFileForShareMeeting = (fileId: string, fileName: string) => {
		if (selectedMeeting)
			dispatch(downloadMeetingFileShared({ selectedMeetingID: selectedMeeting.iCalUId, fileId })).then(
				(result) => {
					saveAs(result.payload as Blob, fileName);
				},
			);
	};

	const [menu, setMenu] = React.useState(false);
	const handleMenu = () => {
		setMenu(!menu);
	};

	const modalRefForm = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefForm.current && !modalRefForm.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	return (
		<>
			<OutlinedButton
				onClick={() => handleMenu()}>
				<IconSimplePlus /> {t('Add a file')}
			</OutlinedButton>
			<div className={styles.files}>
				{files.map((file: FileContract, index: number) => {
					const name = file.name.replace(/(https?:\/\/)?(www\.)?/, '');
					const styledIcon = Object.keys(defaultStyles);

					const extension = name.split('.')[1];

					return (
						<div key={index}>
							<div className={styles.file}>
								<div>
									{file.type === 'URL' ? (
										<div className={styles.link}>
											<div className={styles.globe}>
												<img
													src={`https://www.google.com/s2/favicons?domain=${name}&sz=${256}`}
													alt=''
												/>
											</div>

											<div className={styles.fileName}>
												<a target='_blank' rel='noreferrer' href={file.location}>
													{name}
												</a>
												<div className={styles.accessRight}>
													<div>
														<p>{t(file.accessRightType)}</p>
													</div>
													{userId === file.graphUserId && (
														<div
															className={styles.iconDown}
															onClick={() => handleFileUpdateClick(file, index)}>
															<IconEdit />
														</div>
													)}
												</div>
											</div>
										</div>
									) : (
										<>
											<div className={styles.extensionFile}>
												<Icon
													{...getFileTypeIconProps({
														extension: file?.name.split('.')[1],
														size: 24,
													})}
												/>
											</div>
											<div className={styles.fileName}>
												<a
													target='_blank'
													rel='noreferrer'
													style={{ cursor: 'pointer' }}
													onClick={() =>
														user
															? handleDownloadFile(file.id, file.name)
															: handleDownloadFileForShareMeeting(file.id, file.name)
													}>
													{file.name}
												</a>
												<div className={styles.accessRight}>
													<div>
														<p>{t(file.accessRightType)}</p>
													</div>
													{userId === file.graphUserId && (
														<div
															className={styles.iconDown}
															onClick={() => handleFileUpdateClick(file, index)}>
															<IconEdit />
														</div>
													)}
												</div>
											</div>
										</>
									)}
								</div>

								{userId === file.graphUserId ? (
									<div className={styles.close} onClick={() => handleFileDeleteClick(file)}>
										<IconDelete />
									</div>
								) : (
									<></>
								)}
							</div>
							{openUpdateAccessRight === index && fileToUpdate && (
								<MeetingFilesShareFormModal
									file={fileToUpdate}
									userId={userId}
									selectedMeeting={selectedMeeting}
									mail={mail}
									close={handleUpdateFile}
								/>
							)}
						</div>
					);
				})}
			</div>

			{menu && (
				<div className={styles.form} ref={modalRefForm}>
					<p>
						<div className={styles.iconTitle}>
							<IconDoc />
						</div>{' '}
						{t('Add a file')}
					</p>
					<MeetingsFormFiles userId={userId} close={handleMenu} />
				</div>
			)}

			{openConfirmBox && fileToDelete && (
				<DeleteConfirmBox
					handleDelete={handleDeleteFiles}
					handleConfirmBox={handleConfirmBox}
					message='Are you sure you want to delete this file?'
				/>
			)}
		</>
	);
};

export default MeetingsFilesListComponent;
